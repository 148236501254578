import { AvaConfiguration } from './ava-configurations';
import versionInfo from '../../version-info.json';

export const environment: AvaConfiguration = {
    appVersion: `${versionInfo.version}.dev`,
    buildNumber: versionInfo.buildNumber,
    production: false,
    defaultImageSrc: './assets/images/default.png',
    defaultCountry: 'IT',
    defaultLanguage: 'it',
    enableErrorLogging: false,
    enableErrorReporting: false,
    enableMultiCurrency: false,
    enableQueryLogs: true,
    enablePerformanceLogs: true,
    defaultCurrency: 'EUR',
    bufferTime: 10,
    maxBufferSize: 5,
    disableBuffer: false,
    subqueryLimit: 10,
    disableCache: false,
    encryptResponse: false,
    cartRetryLimit: 3,
    productIdentifier: 'Id',
    type: 'Salesforce',
    debounceTime: 1000,
    proxy: 'https://apttus-proxy.herokuapp.com',
    useIndexedDB: true,
    expandDepth: 8,
    skipPricing: true,
    skipRules: false,
    pricingMode: 'turbo',
    aboPricingMode: 'default',
    packageNamespace: 'Apttus_WebStore',
    storefront: 'EGL',
    organizationId: '00D3O0000008nt2', // Puntamento di dev-bf
    endpoint: 'https://egl-apttus--bfdev1.sandbox.my.site.com', // Puntamento di dev-bf
    auth: {
        clientId: 'b57f538a-b4fa-4e97-a21c-56443fade0a0',
        tenantId: 'c16e514b-893e-4a01-9a30-b8fef514a650',
        redirectUri: 'https://salesapp-bfd1.enigaseluce.com',
    },
};
