/**
 * @author Natalia Veras Campos, Omar Sobrino ft. Marco Ricupero
 * @version 1.2
 * @description PLEASE DO NOT CHANGE, ASK BEFORE
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { map, tap, distinctUntilChanged } from 'rxjs/operators';
import { AptCommodityType } from '../../enums/apttus/apt-commodity-typeof-sale';
import { FormControlStatus } from '../../common.types';
import {
    TroubleshootingFormValues,
    TroubleshootingQuestion,
    TroubleshootingQuestionsService,
    TroubleshootingTemplates,
} from '../../services/shared/troubleshooting-questions.service';
import { Questions, TroubleshootingValuesService } from '../../services/shared/troubleshooting-values.service';
import { TroubleshooterPipe, TroubleshootingExternalParameters } from './troubleshooter.pipe';
import { FlowType } from '../../../store/models/flow-type';
import { Store } from '@ngrx/store';
import { EglState } from '../../../store/reducers';
import { selectCartSegment } from '../../../store/selectors/user.selectors';

export type TroubleShootingResultType = { flowType: FlowType; questionResult: number } | null;

@Component({
    selector: 'egl-troubleshooting-modalita',
    templateUrl: './troubleshooting-modalita.component.html',
    styleUrls: ['./troubleshooting-modalita.component.scss'],
})
export class TroubleshootingModalitaComponent implements OnInit, OnDestroy, OnChanges {
    @Input() commodityType: AptCommodityType;
    @Input() troubleshootingTemplate: TroubleshootingTemplates;
    @Output() response: EventEmitter<TroubleShootingResultType> = new EventEmitter<TroubleShootingResultType>();

    public troubleshootingForm: FormGroup;
    public questionResult: number;
    private subs: Subscription[] = [];
    public troubleshootingParams: TroubleshootingExternalParameters;

    constructor(
        private troubleshootingQuestionsService: TroubleshootingQuestionsService,
        private troubleshootingValuesService: TroubleshootingValuesService,
        private troubleShooter: TroubleshooterPipe,
        protected store: Store<EglState>
    ) {}

    public get template(): TroubleshootingQuestion[] {
        return this.troubleshootingQuestionsService?.QUESTIONS[this.troubleshootingTemplate] || null;
    }

    ngOnInit(): void {
        this.questionResult = this.getInitialValue();

        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.commodityType?.currentValue) {
            this.questionResult = this.getInitialValue();
        }

        if (changes.troubleshootingTemplate?.currentValue) {
            this.initForm();
        }
    }

    ngOnDestroy() {
        this.subs.forEach((s) => s.unsubscribe());
    }

    private initForm() {
        if (!this.template) {
            return;
        }

        this.troubleshootingForm = new FormGroup(
            this.template.reduce(
                (aggr, question) => ({
                    ...aggr,
                    [question.value]: new FormControl(null),
                }),
                {}
            ),
            [
                (control) =>
                    this.troubleShooter
                        .transform(this.template, this.questionResult, control?.value, this.troubleshootingParams)
                        .reduce(
                            (aggr, { value }) =>
                                typeof control?.value[`${value}`] !== 'number'
                                    ? {
                                          ...aggr,
                                          [`${value}`]: 'required',
                                      }
                                    : aggr,
                            {}
                        ),
            ]
        );

        this.subs.push(
            this.store
                .select(selectCartSegment)
                .subscribe((customerSegment) => (this.troubleshootingParams = { customerSegment })),
            merge(
                this.troubleshootingForm.valueChanges.pipe(
                    map((values: TroubleshootingFormValues) => ({
                        currentQuestionResult: this.calculateQuestionResult(this.template, this.questionResult, values),
                        values,
                    })),
                    map(({ currentQuestionResult, values }) => ({
                        questionResult: this.calculateQuestionResult(this.template, currentQuestionResult, values),
                        values,
                    })),
                    map(({ questionResult, values }) => ({
                        isValid: !this.troubleShooter
                            .transform(this.template, questionResult, values, this.troubleshootingParams)
                            .some(({ value }) => typeof values[`${value}`] !== 'number'),
                        questionResult,
                    })),
                    tap(({ isValid, questionResult }) => {
                        this.questionResult = questionResult;
                        if (this.troubleshootingForm.valid !== isValid) {
                            this.troubleshootingForm.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                        }
                    })
                ),
                this.troubleshootingForm.statusChanges.pipe(
                    map(
                        (status: FormControlStatus) =>
                            status === 'VALID' &&
                            !this.troubleShooter
                                .transform(
                                    this.template,
                                    this.questionResult,
                                    this.troubleshootingForm.value,
                                    this.troubleshootingParams
                                )
                                .some(({ value }) => typeof this.troubleshootingForm.value[`${value}`] !== 'number')
                    ),
                    map((isValid) => ({
                        isValid,
                        questionResult: this.questionResult,
                    }))
                )
            )
                .pipe(
                    map(({ isValid, questionResult }) =>
                        isValid
                            ? {
                                  flowType: this.troubleshootingValuesService.matchSalesProcess(questionResult) || null,
                                  questionResult,
                              }
                            : null
                    ),
                    distinctUntilChanged((prev, cur) => prev?.questionResult === cur?.questionResult),
                    tap((response: TroubleShootingResultType) => this.response.emit(response))
                )
                .subscribe()
        );
    }

    private calculateQuestionResult(
        questions: TroubleshootingQuestion[],
        currentQuestionResult: number,
        values: TroubleshootingFormValues
    ): number {
        const availableValues = this.troubleShooter
            .transform(questions, currentQuestionResult, values, this.troubleshootingParams)
            .map(({ value }) => value);
        return (
            this.getInitialValue() +
            Object.values(values)
                .filter((value) => typeof value === 'number' && value > 0 && availableValues.includes(value))
                .reduce((aggr, value) => aggr + (value || 0), 0)
        );
    }

    private getInitialValue(): number {
        if (this.commodityType === AptCommodityType.Power) {
            return Questions.POWER;
        } else if (this.commodityType === AptCommodityType.Gas) {
            return Questions.GAS;
        }
        return 0;
    }
}
