import { Expose } from 'class-transformer';

export class D365RetrieveUpsertCase {
    @Expose()
    title: string;

    @Expose()
    ticketnumber: string;

    @Expose({ name: 'caseorigincode' })
    caseorigincode: string;

    @Expose({ name: 'egl_casecharacteristic' })
    casecharacteristic: string;

    @Expose()
    incidentid: string;

    @Expose({ name: 'caseorigin.egl_code' })
    caseorigin: string;

    @Expose({ name: 'cl1.egl_code' })
    caselevel1code: string;

    @Expose({ name: 'cl2.egl_code' })
    caselevel2code: string;

    @Expose({ name: 'cl3.egl_code' })
    caselevel3code: string;

    @Expose({ name: 'customerdata.accountnumber' })
    accountnumber: string;
}

export interface D365CaseCloseResponse {
    APICaseNumber: string;
    APIResultDetail: string;
    APIResult: string;
    APIResultCode: string;
}

export type UpsertCaseReq = {
    caseNumber?: string;
    processCode: string;
    customer: {
        customerType: string;
        customerCode: string;
    };
    channel: string;
    caseCharacteristic: string;
    caseLevel1Code: string;
    caseLevel2Code: string;
    caseLevel3Code: string;
    status?: string;
    caseResolutionReason?: string;
    caseOrigin: string;
    validationUser?: string;
    quoteCode?: string;
};
