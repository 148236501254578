import { EventEmitter, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { CartItem, LineItemService, Product } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { setV2ProductByIdx, setV2ProductTaxDeduction } from '../../../../store/actions/order-entry-v2.actions';
import { FlowType, MacroFlowType } from '../../../../store/models/flow-type';
import { Product as ProductState } from '../../../../store/models/order-entry-state_v2';
import { MastershipType } from '../../../../store/models/user-state';
import { EglState } from '../../../../store/reducers';
import {
    v2SelectFlowType,
    v2SelectMastership,
    v2SelectPendingAssetCommodities,
} from '../../../../store/selectors/order-entry-v2.selectors';
import { selectDestinationUse, selectSalesProcess } from '../../../../store/selectors/order-entry.selectors';
import { selectCartSegment } from '../../../../store/selectors/user.selectors';
import { EglInputFieldComponent } from '../../../components/apttus/egl-input-field/egl-input-field.component';
import { AptBillType } from '../../../enums/apttus/apt-bill-type';
import { AptPaymentInstrument } from '../../../enums/apttus/apt-payment-instrument';
import { AptPaymentType } from '../../../enums/apttus/apt-payment-type';
import { AptSalesProcess } from '../../../enums/apttus/apt-sales-process';
import { TYPE_OF_USE_APTTUS_FIELD } from '../../../enums/shared/destination-use.enum';
import { cleanObj, isActiveNotTechProduct, isNotDiscountItem } from '../../../functions/misc.functions';
import { mapOrEmpty } from '../../../functions/observable-operators';
import { convertSegmentD365toApt, v2ProductToLineItemAttributes } from '../../../functions/remap.functions';
import {
    containsCommodity,
    containsProductComplex,
    containsProductMaintenance,
    containsProductSmartHome,
    flowTypeUtil,
    isVariazioneTecnicaWithVoltageNeeded,
} from '../../../functions/verifications.functions';
import { EglProductAttributeValue } from '../../../models/apttus/tables/cart/egl-cart-item-extended';
import { CommonProvider } from '../../../providers/common-provider';
import { LoggerService } from '../../shared/logger.service';
import { EglSalesEligibilityService } from '../tables/cart/egl-sales-eligibility.service';
import { EglTaxDeductionService } from '../tables/taxDeduction/egl-tax-deduction.service';

interface FieldValidationError {
    message: string;
    field?: string;
    value?: string | number | Date;
}

@Injectable({ providedIn: 'root' })
export class EglApttusFieldValidationProvider {
    inputFields: EglInputFieldComponent[] = [];
    loadedRules = new EventEmitter<boolean>();

    constructor(
        private store: Store<EglState>,
        private commonPrv: CommonProvider,
        private translateSrv: TranslateService,
        private salesEligibilityService: EglSalesEligibilityService,
        private taxDeductionSrv: EglTaxDeductionService,
        private logger: LoggerService,
    ) {}

    addInputFields(field: EglInputFieldComponent): void {
        this.inputFields.push(field);
    }

    clear(): void {
        this.inputFields = [];
    }

    clearField(id: string): void {
        const removedElements = this.inputFields.filter((x) => x.inputId !== id);
        this.inputFields = removedElements;
    }

    /**
     * Return option error strings. If array empty no error occurs.
     * @param product
     * @returns
     */
    optionGroupsErrors(product: Product): string[] {
        const optionErrors = product?.OptionGroups?.map((optionGroup) => optionGroup?.errors);
        const errrorMap = optionErrors?.reduce(
            (agg, curr, idx) => {
                const errorlList = curr.map(
                    (error) => <string>this.translateSrv.instant(error.message, error.parameter),
                );
                return {
                    ...agg,
                    [curr?.[0]?.reference?.value || `${idx}_`]: errorlList,
                };
            },
            {} as { [key in string]?: string[] },
        );

        return Object.values(errrorMap || {}).flat();
    }

    /**
     * Return attribute error strings. If array empty no error occurs.
     * @param product
     * @returns
     */
    attributeFieldsErrors(
        cartItemList: Array<CartItem>,
        flowType: FlowType,
        forCartItemsOnly?: boolean,
    ): FieldValidationError[] | null {
        cartItemList = cartItemList || [];
        const firstCartItem = cartItemList[0];
        const errors = [];

        if (!this.inputFields || this.inputFields.length === 0) {
            // no fields to validate
            return [];
        }

        if (
            forCartItemsOnly &&
            isVariazioneTecnicaWithVoltageNeeded(flowType) &&
            firstCartItem &&
            !firstCartItem.AttributeValue['egl_voltage__c']
        ) {
            errors.push({
                message: 'Attenzione. Non è possibile continuare, sul prodotto non è valorizzata la tensione.',
            });
        }
        if (
            ((forCartItemsOnly && flowType === FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza) ||
                flowType === FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa) &&
            firstCartItem &&
            firstCartItem.AttributeValue['egl_engaged_power_required__c'] &&
            firstCartItem.AttributeValue['egl_Required_Power__c'] &&
            Number(firstCartItem.AttributeValue['egl_Required_Power__c']) >=
                Number(firstCartItem.AttributeValue['egl_engaged_power_required__c'])
        ) {
            errors.push({
                message:
                    'Attenzione. Si prega di inserire una potenza contrattuale richiesta superiore alla potenza contrattuale.',
            });
        }

        if (
            ((forCartItemsOnly && flowType === FlowType.VariazioneTecnicaLavoriDiminuzionePotenza) ||
                flowType === FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa) &&
            firstCartItem &&
            firstCartItem.AttributeValue['egl_engaged_power_required__c'] &&
            firstCartItem.AttributeValue['egl_Required_Power__c'] &&
            Number(firstCartItem.AttributeValue['egl_Required_Power__c']) <=
                Number(firstCartItem.AttributeValue['egl_engaged_power_required__c'])
        ) {
            errors.push({
                message:
                    'Attenzione. Si prega di inserire una potenza contrattuale richiesta inferiore alla potenza contrattuale.',
            });
        }

        if (
            ((forCartItemsOnly && flowType === FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi) ||
                flowType === FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa) &&
            firstCartItem &&
            firstCartItem.AttributeValue['egl_num_of_phases_available__c'] &&
            firstCartItem.AttributeValue['egl_num_of_phases_required__c'] &&
            firstCartItem.AttributeValue['egl_num_of_phases_required__c'] ===
                firstCartItem.AttributeValue['egl_num_of_phases_available__c']
        ) {
            errors.push({
                message: 'Attenzione. Inserire un valore N. Fasi Richiesto differente da N. Fasi Disponibile',
            });
        }

        if (
            ((forCartItemsOnly && flowType === FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione) ||
                flowType === FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa) &&
            firstCartItem &&
            firstCartItem.AttributeValue['egl_voltage__c'] &&
            firstCartItem.AttributeValue['egl_voltage_required__c'] &&
            firstCartItem.AttributeValue['egl_voltage_required__c'] === firstCartItem.AttributeValue['egl_voltage__c']
        ) {
            errors.push({
                message: 'Attenzione. Inserire un valore Tensione Richiesta differente da Tensione',
            });
        }

        const nPhaseAvailable = this.inputFields.filter((field) => field.field.match('egl_num_of_phases_available__c'));
        const nPhaseRequired = this.inputFields.filter((field) => field.field.match('egl_num_of_phases_required__c'));

        if (
            [
                FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
                FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
            ].includes(flowType) &&
            forCartItemsOnly &&
            firstCartItem &&
            nPhaseAvailable[0].value &&
            nPhaseRequired[0].value &&
            nPhaseAvailable[0].value === 'TRIFASE' &&
            nPhaseRequired[0].value === 'MONOFASE'
        ) {
            errors.push({
                message: 'Per il valore di potenza richiesto e di fase attuale, non è consentito il cambio di fase',
            });
        }

        if (
            [
                FlowType.VariazioneTecnicaLavoriDiminuzionePotenza,
                FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa,
            ].includes(flowType) &&
            forCartItemsOnly &&
            firstCartItem &&
            nPhaseAvailable[0].value &&
            nPhaseRequired[0].value &&
            nPhaseAvailable[0].value === 'MONOFASE' &&
            nPhaseRequired[0].value === 'TRIFASE'
        ) {
            errors.push({
                message: 'Per il valore di potenza richiesto e di fase attuale, non è consentito il cambio di fase',
            });
        }

        const invalidDates = this.inputFields.find(
            (field) => typeof field.value === 'string' && field.value?.toLowerCase() === 'invalid date',
        );

        if (invalidDates) {
            errors.push({
                message: 'Attenzione. Si prega di inserire una data valida.',
                field: invalidDates.field,
                value: invalidDates.value,
            });
        }

        const primaryCartLineItems = this.clearCartLineItems(cartItemList);
        //controllo inserito a seguito di BUG 262510
        const filteredList = this.inputFields.filter((field) => field.field.match('egl_Payment_Instrument__c'));
        const input = ['egl_Payment_Type__c', 'egl_Payment_Frequency__c', 'egl_installment_duration__c'];
        if (filteredList.length == 1 && filteredList[0].value !== 'Bonifico Detrazione Fiscale') {
            for (let i = 0; i < this.inputFields.length; i++) {
                if (this.inputFields[i].field == 'egl_tax_detraction__c') {
                    this.inputFields[i].required = false;
                }
            }
        }
        if (filteredList.length == 1 && filteredList[0].value === 'Credito al Consumo') {
            for (let i = 0; i < this.inputFields.length; i++) {
                if (input.includes(this.inputFields[i].field)) {
                    this.inputFields[i].required = false;
                }
            }
        }
        const requiredFields = this.inputFields.filter((field) => field.required && !field.disabled && !field.hide);

        for (let i = 0, j = requiredFields.length; i < j; ++i) {
            const input = requiredFields[i] as EglInputFieldComponent;
            if (!input.value) {
                errors.push({
                    message: 'Attenzione. Compila tutti i campi obbligatori.',
                    field: input.field,
                    value: input.value,
                });
            }

            const isSelf = (primaryCartLineItems || []).find(
                (cartItem) => cartItem?.Product?.Id === input?.product?.Id,
            );
            if (isSelf && primaryCartLineItems?.length === 1) {
                continue;
            }
        }
        return errors;
    }

    /**
     * @description Controlli sulla modalità di spedizione e metodo di pagamento. I controlli vengono saltati per le extracommodity all'aggiunta carrello per essere riproposti alla creazione dell'ordine.
     * @param cartItemList I prodotti nel carrello
     * @param isEditingProduct Flag che indica se si sta nella modalità di modifica del prodotto
     * @returns Array degli errori restituiti
     */
    public paymentAndShippingFieldsErrors(
        cartItemList: Array<CartItem>,
        isEditingProduct: boolean,
    ): FieldValidationError[] {
        const requiredInputs = this.inputFields.filter((field) => field.required && !field.disabled && !field.hide);
        const onlyVisibleItems = this.clearCartLineItems(cartItemList);
        const firstCartItem = onlyVisibleItems[0];
        const errors: FieldValidationError[] = [];
        this.store.select(v2SelectFlowType).pipe(take(1)).subscribe((flow) => (flowType = flow));
        let flowType: FlowType;

        // Skip validation if there is one item in cart and that item is being edited or if it is Cambio Prodotto because you should be able to update the payment method
        if (onlyVisibleItems.length === 1 && isEditingProduct || flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.CambioProdotto)) return [];

        // Create a mapping to check if payment and shipping methods match with the configuration when the first cart item was added
        // This allows to have a congruent configuration between all cart items when they are added and when they are edited
        const paymentShippingMatch: { [key in keyof EglProductAttributeValue]?: boolean } = requiredInputs
            .filter((input) => ['egl_Invoice_Shipping_Method__c', 'egl_Payment_Instrument__c'].includes(input.field))
            .reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur.field]: cur.value === firstCartItem.AttributeValue[cur.field],
                }),
                {},
            );

        // Check for shipping method compatibility error
        if (
            paymentShippingMatch.hasOwnProperty('egl_Invoice_Shipping_Method__c') &&
            !paymentShippingMatch.egl_Invoice_Shipping_Method__c
        ) {
            errors.push({
                message: 'Modalità di spedizione non compatibile con gli altri prodotti.',
                field: 'egl_Invoice_Shipping_Method__c',
                value: null,
            });
        }
        // Check for payment instrument compatibility error
        if (
            paymentShippingMatch.hasOwnProperty('egl_Payment_Instrument__c') &&
            !paymentShippingMatch.egl_Payment_Instrument__c
        ) {
            errors.push({
                message: 'Metodo di pagamento diverso rispetto a quello già selezionato.',
                field: 'egl_Payment_Instrument__c',
                value: null,
            });
        }
        return errors;
    }

    checkItems(req: {
        salesProcess?: AptSalesProcess;
        cartId?: string;
        productId?: string;
        configuration?: Params;
        paymentInstrument?: string;
        editedLineItemId?: string;
    }): Observable<void> {
        return combineLatest([
            this.store.select(v2SelectMastership),
            this.store.select(selectSalesProcess),
            this.store.select(selectDestinationUse),
            this.store.select(selectCartSegment).pipe(map((d365Segment) => convertSegmentD365toApt(d365Segment))),
            this.store.select(v2SelectPendingAssetCommodities()).pipe(
                map((products) =>
                    products.reduce(
                        (aggr, product) => ({
                            ...aggr,
                            ...v2ProductToLineItemAttributes(product),
                        }),
                        {} as Params,
                    ),
                ),
            ),
        ]).pipe(
            take(1),
            map(
                ([
                    { sourceCustomerMastership, customerMastership },
                    salesProcess,
                    destinationUse,
                    customerSegment,
                    assetCommoditiesParams,
                ]) => ({
                    salesProcess,
                    configuration: {
                        // Definisco i campi di default da inviare in base a quello che ho nello state
                        ...assetCommoditiesParams,
                        customerMastership,
                        sourceCustomerMastership,
                        customerSegment,
                        paymentInstrument: req?.paymentInstrument,
                        [TYPE_OF_USE_APTTUS_FIELD]: destinationUse,
                        // Sovrascrivo i campi di default con quelli in ingresso,
                        ...cleanObj(req?.configuration || {}),
                    },
                }),
            ),
            map(({ salesProcess, configuration }) => ({
                operationType: req?.salesProcess || salesProcess,
                productConfigurationId: req?.cartId,
                productId: req?.productId,
                // Ricostruisco la struttura come attesa dal servizio: array di oggetti con field e value
                configuration: Object.entries(configuration)
                    .filter(([, value]) => !!value || ['boolean', 'number'].includes(typeof value))
                    .reduce((aggr, [field, value]) => [...aggr, { field, value }], []),
                editedLineItemId: req?.editedLineItemId,
            })),
            mergeMap((enrichedRequest) => this.commonPrv.checkItems(enrichedRequest)),
        );
    }

    checkItemsAnticipated(
        flowType: AptSalesProcess,
        customerMastership: MastershipType,
        sourceCustomerMastership: MastershipType,
    ): Observable<void> {
        return this.commonPrv.checkItems({
            operationType: flowType,
            configuration: [
                sourceCustomerMastership && { field: 'sourceCustomerMastership', value: sourceCustomerMastership },
                customerMastership && { field: 'customerMastership', value: customerMastership },
            ].filter(Boolean),
        });
    }

    /**
     * @description Escludo dalla validazione i lineitem dei prodotti tecnici (tipo Fornitura Power) e gli sconti standalone che non sono modificabili
     * @param cartItemList
     * @returns Array di cart item filtrato
     */
    public clearCartLineItems(cartItemList: CartItem[]): CartItem[] {
        return LineItemService.groupItems(cartItemList.filter(isActiveNotTechProduct).filter(isNotDiscountItem))
            .map((group) => group.PrimaryLines as CartItem[])
            .flat();
    }

    hasEmptyRequiredField(): boolean {
        return !!this.inputFields.find(
            (field) => field.required && !field.disabled && !field.hide && [null, undefined].includes(field.value),
        );
    }

    /**
     * @description Controlli di vendibilità rispetto alla tabella egl_payment_type__mdt in cui i valori selezionati per i prodotti devono essere congruenti fra di loro.
     * Esempio: Tipologia di pagamento: Unica Soluzione, Modalità di pagamento: Addebito su Carta di Credito, Addebito bolletta: NO - Match presente, si fa ordine.
     */
    getSalesEligibilityRulesErrors(
        cleanProducts: (ProductState & {
            visibleIdx: number;
        })[],
    ): Observable<CompatibilityCheckModalProduct[]> {
        let mainProducts: (ProductState & {
            visibleIdx: number;
        })[];
        return of(cleanProducts).pipe(
            map((products) =>
                products.filter(
                    (p) => containsProductMaintenance([p.productType]) || containsProductSmartHome([p.productType]),
                ),
            ),
            filter((products) => {
                mainProducts = products;
                return !!products.length;
            }),
            mergeMap((products) => this.salesEligibilityService.getProductClass(products)),
            tap((data) => {
                data.forEach((d) => {
                    this.store.dispatch(
                        setV2ProductByIdx({
                            productIdx: d.productData.idx,
                            product: {
                                configurations: {
                                    productClass: d.productClass,
                                    creditAssignment: d.creditAssignment,
                                    installmentFlag: d.installmentFlag,
                                    withHoldingTax: d.withHoldingTax,
                                },
                            },
                        }),
                    );
                });
            }),
            map((data) => data.filter((d) => !d.productClass)),
            mapOrEmpty(
                (data) => {
                    const error = this.translateSrv.instant(
                        'ERROR.CART.COMPATIBILITY_CHECK_MODAL.ERROR_TYPES.SALEABILITY_CPQ_RULE',
                    );
                    return data.map(({ productData }) => ({
                        status: 'important_3',
                        order: productData.visibleIdx,
                        product: productData.name,
                        error,
                        paymentType: productData.paymentType,
                        paymentInstrument: productData.paymentInstrument,
                        shippingMethod: productData.shippingMethod,
                        billCharge: productData.billCharge,
                        taxDeductionId:
                            mainProducts.find((p) => p.name === productData.name)?.configurations?.productTaxDeduction
                                ?.taxDeductionId || '---',
                        lineItemId: productData.lineItemId,
                    }));
                },
                () => [],
            ),
        );
    }

    /**
     * @description Controllo di congruenza fra i valori di modalità di pagamento e modalità di spedizione dei cartItems rispetto al primo cartItem.
     */
    // getSamePaymentAndShippingErrors(
    //     cleanProducts: (ProductState & {
    //         visibleIdx: number;
    //     })[],
    // ): Observable<CompatibilityCheckModalProduct[]> {
    //     if (cleanProducts.length > 1) {
    //         const {
    //             configurations: { invoiceShippingMethod: firstItemInvoiceShippingMethod },
    //             paymentInfo: { paymentInstrument: firstItemPaymentInstrument },
    //         } = cleanProducts[0];
    //         const referencePaymentType: AptPaymentType = cleanProducts.find(
    //             (p) => containsProductMaintenance(p.productType) || containsProductSmartHome([p.productType]),
    //         )?.paymentInfo?.paymentType;
    //         const incompatibleItems = cleanProducts
    //             .slice(1)
    //             .map(
    //                 ({
    //                     name,
    //                     visibleIdx,
    //                     lineItemId,
    //                     configurations: { invoiceShippingMethod, billCharge, productTaxDeduction },
    //                     paymentInfo: { paymentInstrument, paymentType },
    //                 }) => ({
    //                     sameShipping: invoiceShippingMethod === firstItemInvoiceShippingMethod,
    //                     samePaymentInstrument: paymentInstrument === firstItemPaymentInstrument,
    //                     samePaymentType: !paymentType || paymentType === referencePaymentType,
    //                     productData: {
    //                         name,
    //                         paymentInstrument,
    //                         paymentType,
    //                         invoiceShippingMethod,
    //                         billCharge,
    //                         visibleIdx,
    //                         lineItemId,
    //                     },
    //                     productTaxDeduction,
    //                 }),
    //             )
    //             .filter((item) => !item.sameShipping || !item.samePaymentInstrument || !item.samePaymentType)
    //             .map(({ productData, samePaymentInstrument, samePaymentType, sameShipping, productTaxDeduction }) => {
    //                 const baseItem: CompatibilityCheckModalProduct = {
    //                     status: 'important_3',
    //                     order: productData.visibleIdx,
    //                     product: productData.name,
    //                     paymentType: productData.paymentType,
    //                     paymentInstrument: productData.paymentInstrument,
    //                     shippingMethod: productData.invoiceShippingMethod,
    //                     billCharge: productData.billCharge ? 'SI' : 'NO',
    //                     taxDeductionId: productTaxDeduction?.taxDeductionId || '---',
    //                     lineItemId: productData.lineItemId,
    //                 };
    //                 const errors: CompatibilityCheckModalProduct[] = [];
    //                 !samePaymentInstrument &&
    //                     errors.push({
    //                         ...baseItem,
    //                         error: this.translateSrv.instant(
    //                             'ERROR.CART.COMPATIBILITY_CHECK_MODAL.ERROR_TYPES.PAYMENT_INSTRUMENT',
    //                         ),
    //                     });
    //                 !samePaymentType &&
    //                     errors.push({
    //                         ...baseItem,
    //                         error: this.translateSrv.instant(
    //                             'ERROR.CART.COMPATIBILITY_CHECK_MODAL.ERROR_TYPES.PAYMENT_TYPE',
    //                         ),
    //                     });
    //                 !sameShipping &&
    //                     errors.push({
    //                         ...baseItem,
    //                         error: this.translateSrv.instant(
    //                             'ERROR.CART.COMPATIBILITY_CHECK_MODAL.ERROR_TYPES.SHIPPING_METHOD',
    //                         ),
    //                     });
    //                 return errors;
    //             })
    //             .reduce((aggr, curr) => [...aggr, ...curr], []);
    //         return of(incompatibleItems);
    //     }
    //     return of([]);
    // }

    /**
     * @description Controllo del campo egl_addebitobolletta__c. Nei carrelli misti (commodity + extra commodity) i prodotti extra commodity devono averlo settato a SI. Un prodotto extra da solo deve essere a NO.
     */
    getisBillChargeTrueErrors(
        cleanProducts: (ProductState & {
            visibleIdx: number;
        })[],
    ): Observable<CompatibilityCheckModalProduct[]> {
        const hasCommodityProducts = cleanProducts.some((p) => containsCommodity(p.productType));
        const ndsProductsWithoutBillCharge = cleanProducts.filter(
            (p) =>
                (containsProductMaintenance([p.productType]) || containsProductSmartHome([p.productType])) &&
                !p.configurations.billCharge,
        );
        const ndsProductsWithBillCharge = cleanProducts.filter(
            (p) =>
                (containsProductMaintenance([p.productType]) || containsProductSmartHome([p.productType])) &&
                p.configurations.billCharge,
        );
        const errorProducts =
            hasCommodityProducts && ndsProductsWithoutBillCharge.length
                ? ndsProductsWithoutBillCharge
                : !hasCommodityProducts && ndsProductsWithBillCharge.length
                  ? ndsProductsWithBillCharge
                  : [];
        const error = this.translateSrv.instant('ERROR.CART.COMPATIBILITY_CHECK_MODAL.ERROR_TYPES.BILL_CHARGE');
        return of(
            errorProducts.map(({ visibleIdx, name, paymentInfo, configurations, lineItemId }) => ({
                status: 'important_3',
                order: visibleIdx,
                product: name,
                error,
                paymentType: paymentInfo.paymentType,
                paymentInstrument: paymentInfo.paymentInstrument,
                shippingMethod: configurations.invoiceShippingMethod,
                billCharge: configurations.billCharge ? 'SI' : 'NO',
                taxDeductionId: configurations.productTaxDeduction?.taxDeductionId || '---',
                lineItemId,
            })),
        );
    }

    getIsTaxDeductionErrors(
        cleanProducts: (ProductState & {
            visibleIdx: number;
        })[],
    ): Observable<CompatibilityCheckModalProduct[]> {
        const firstTaxDeductionCode = cleanProducts.find((p) => p.configurations?.productTaxDeduction?.taxDeductionId)
            ?.configurations?.productTaxDeduction?.taxDeductionId;
        const allTaxDeductionProductsCodeDiff = cleanProducts.filter(
            (p) => p.configurations?.productTaxDeduction?.taxDeductionId !== firstTaxDeductionCode,
        );

        cleanProducts
            .filter((p) => p.configurations?.productTaxDeduction?.taxDeductionId)
            .map((p) => {
                this.taxDeductionSrv
                    .getTaxDeductionDescription(p.configurations?.productTaxDeduction?.taxDeductionId)
                    .subscribe((productTaxDescriptions) => {
                        this.store.dispatch(
                            setV2ProductTaxDeduction({
                                productTaxDeduction: {
                                    taxDeductionDescription: productTaxDescriptions[0] as string,
                                },
                            }),
                        );
                    });
            });

        const errorProducts = allTaxDeductionProductsCodeDiff || [];
        const error = this.translateSrv.instant('ERROR.CART.COMPATIBILITY_CHECK_MODAL.ERROR_TYPES.TAX_DEDUCTION');
        return of(
            errorProducts.map(({ visibleIdx, name, paymentInfo, configurations, lineItemId }) => ({
                status: 'important_3',
                order: visibleIdx,
                product: name,
                error,
                paymentType: paymentInfo.paymentType,
                paymentInstrument: paymentInfo.paymentInstrument,
                shippingMethod: configurations.invoiceShippingMethod,
                billCharge: configurations.billCharge ? 'SI' : 'NO',
                taxDeductionId: configurations.productTaxDeduction?.taxDeductionId || '---',
                lineItemId,
            })),
        );
    }

    //Se il prodotto è manutenzione e non ci sono le characteristics recuperate dal metodo getProductCharacteristics nell ese.provider.ts sollevo un errore
    getMaintenanceWithoutChatacteristicsErrors(
        cleanProducts: (ProductState & {
            visibleIdx: number;
        })[],
    ): Observable<CompatibilityCheckModalProduct[]> {
        const hasMaintenanceProductWithoutCharacteristics = cleanProducts.filter(
            (p) =>
                //TODO: aggiungere il controllo che determina se il prodotto complex ha bisogno del sopralluogo
                (containsProductMaintenance(p.productType) || containsProductComplex(p.productType)) &&
                (!p?.technicalDetails?.characteristics || p?.technicalDetails?.characteristics?.length === 0),
        );
        const errorProducts = hasMaintenanceProductWithoutCharacteristics
            ? hasMaintenanceProductWithoutCharacteristics
            : [];
        const error = this.translateSrv.instant(
            'ERROR.CART.COMPATIBILITY_CHECK_MODAL.ERROR_TYPES.MAINTENANCE_PRODUCT_WITHOUT_CHARACTERISTICS',
        );
        return of(
            errorProducts.map(({ visibleIdx, name, paymentInfo, configurations, lineItemId }) => {
                this.logger.error(null, error, error, true);
                return {
                    status: 'important_3',
                    order: visibleIdx,
                    product: name,
                    error,
                    paymentType: paymentInfo.paymentType,
                    paymentInstrument: paymentInfo.paymentInstrument,
                    shippingMethod: configurations.invoiceShippingMethod,
                    billCharge: configurations.billCharge ? 'SI' : 'NO',
                    lineItemId,
                };
            }),
        );
    }
}

export interface CompatibilityCheckModalProduct {
    status?: string;
    order: number;
    product: string;
    error?: string;
    paymentType: AptPaymentType;
    paymentInstrument: AptPaymentInstrument;
    shippingMethod: AptBillType;
    billCharge: 'SI' | 'NO';
    taxDeductionId?: string;
    lineItemId: string;
}
