import { Indirizzo } from '../../modules/switch-in/order-entry/models/indirizzi';
import {
    Appointment,
    CostData,
    Domiciliation,
    GasAdministrativeTechnicalData,
    GasConsumption,
    OrderEntryState,
    PotenzaImpegnataMB,
    PowerAdministrativeTechnicalData,
    TermCosts,
    Termination,
} from '../../store/models/order-entry-state';
import {
    CompanyIdentity,
    GasTechnicalDetails,
    OrderAppointment,
    OrderEntryState_v2,
    PersonalIdentity,
    PowerTechnicalDetails,
    Product,
} from '../../store/models/order-entry-state_v2';
import { userStateSelector } from '../../store/selectors/user.selectors';
import { AptBillType } from '../enums/apttus/apt-bill-type';
import { AptCommodityType } from '../enums/apttus/apt-commodity-typeof-sale';
import { AptProductFamily } from '../enums/apttus/apt-product-family';
import { cleanObj, parseToISO8601DateOnly, sumPrices } from './misc.functions';
import { createCSV, getCompleteCivic, getString } from './string-format.functions';
import {
    productFieldSelectorGenerator,
    isGasProduct,
    isPowerProduct,
    isActiveVisibleMainProduct,
} from '../../store/selectors/selector-utility.functions';
import { aptCommodityTypesToWinBackType, salesProcessOrFlowTypeToOperationType } from './remap.functions';
import { FormDatiPagamento } from '../../modules/common/order-entry/models/form-dati-pagamento';
import { FormBonifico, SottoscrittoreSepa, TitolareConto } from '../../modules/common/order-entry/models/form-bonifico';
import {
    isAddressEmpty,
    isCommodityFamily,
    flowTypeUtil,
    isIbanItalian,
    isSameAddress,
    isValidAddress,
} from './verifications.functions';
import { D365CustomerSegment } from '../enums/d365/d365-customer-segment';
import { UserState } from '../../store/models/user-state';
import { FlowType, MacroFlowType } from '../../store/models/flow-type';
import { address2Indirizzo } from './address.functions';
import { AptPaymentInstrument } from '../enums/apttus/apt-payment-instrument';
import { Contact as UserContact } from '../models/user/contact';
import { AptAddressType } from '../enums/apttus/apt-address-type';
import { MD5 } from 'crypto-js';
import { getValidDestinationUse } from './transformation.functions';
import { merge } from 'lodash';

let cacheLatestConventedState: { [key in string]: OrderEntryState } = {};
export function orderEntryV2ToV1(state: OrderEntryState_v2): OrderEntryState {
    const hashState = MD5(JSON.stringify(state)).toString();
    if (!cacheLatestConventedState[hashState]) {
        cacheLatestConventedState = {
            [hashState]: _orderEntryV2ToV1(state),
        };
    }
    return cacheLatestConventedState[hashState];
}

function _orderEntryV2ToV1(dataV2: OrderEntryState_v2, currentProductV2Id?: number): OrderEntryState {
    const currentProducts: Product[] = Array.from(
        productFieldSelectorGenerator({
            state: dataV2,
            productSelector: (product) => product,
            productIdx: currentProductV2Id,
        }),
    );

    const powerProduct = currentProducts.find(isPowerProduct) || ({} as Product);
    const gasProduct = currentProducts.find(isGasProduct) || ({} as Product);
    const mainActiveProduct = currentProducts.find(isActiveVisibleMainProduct) || ({} as Product);
    const commodityProducts =
        currentProducts.filter((product) => isPowerProduct(product) || isGasProduct(product)) || [];

    const wibBackProducts = commodityProducts.reduce(
        (aggr, product) =>
            product?.isWinBack
                ? {
                      ...aggr,
                      [product?.podPdr]: product,
                  }
                : aggr,
        {},
    );

    const currentProduct = merge(
        {},
        cleanObj(powerProduct),
        cleanObj(gasProduct),
        cleanObj(mainActiveProduct),
    ) as Product;

    const userState = userStateSelector.projector();

    const isMicrobusiness = userState?.cartSegment === D365CustomerSegment.Microbusiness;
    const supplyAddress = currentProducts.find(
        (product) => product.addressType === AptAddressType.Fornitura && isValidAddress(product.deliveryAddress),
    )?.deliveryAddress;
    const shippingAddress = currentProducts.find(
        (product) => product.family === AptProductFamily.BeniFisici && isValidAddress(product.deliveryAddress),
    )?.deliveryAddress;
    const communicationAddress = currentProducts.find((product) =>
        isValidAddress(product.communicationAddress),
    )?.communicationAddress;

    const indirizzoFornitura = address2Indirizzo(supplyAddress, true);
    const indirizzoSpedizione = address2Indirizzo(shippingAddress, true);
    const indirizzoComunicazioni = address2Indirizzo(communicationAddress, true);
    const indirizzoResidenzaSedeLegale =
        dataV2?.contact?.mainAddress === false ? null : address2Indirizzo(dataV2?.contact?.mainAddress, true);

    const stessoIndirizzoSpedizione =
        isSameAddress(supplyAddress, shippingAddress) || !indirizzoHasValues(indirizzoSpedizione);
    const stessoIndirizzoComunicazioni =
        isSameAddress(supplyAddress, communicationAddress) || !indirizzoHasValues(indirizzoComunicazioni);

    const stessoIndirizzoResidenza =
        (isAddressEmpty(dataV2?.contact?.mainAddress) && dataV2?.contact?.mainAddress !== false) ||
        (!isAddressEmpty(supplyAddress) && isSameAddress(supplyAddress, dataV2?.contact?.mainAddress)) ||
        (!isAddressEmpty(shippingAddress) && isSameAddress(shippingAddress, dataV2?.contact?.mainAddress)) ||
        (!indirizzoHasValues(indirizzoFornitura) && !isMicrobusiness);

    const isSedeLegale =
        isMicrobusiness &&
        dataV2?.contact?.mainAddress !== false &&
        isSameAddress(currentProduct?.deliveryAddress, dataV2?.contact?.mainAddress);

    const stateV1: OrderEntryState = {
        version: 1,
        flowType: dataV2?.flowType,
        currentProductV2Id: typeof currentProductV2Id === 'number' ? currentProductV2Id : null,
        partNumber: currentProduct?.partNumber,
        operationType: dataV2?.flowType ? salesProcessOrFlowTypeToOperationType(dataV2?.flowType) : null,
        assetIntegrationId: currentProduct?.assetIntegrationId,
        pod: powerProduct?.podPdr || null,
        pdr: gasProduct?.podPdr || null,
        cartInfo: {
            cartId: dataV2.cartId,
            ownerDagCode: dataV2?.dagCode,
        },
        infoProdotti: dataV2?.products?.map((product) => ({
            id: product?.productId,
            code: product?.codeOrSku,
            nome: getString(product?.name),
            productType: product?.productType,
            family: product?.family,
            configuration: {
                billType: product?.configurations?.invoiceShippingMethod,
                paymentInstrument: product?.paymentInfo?.paymentInstrument,
                orderNumber: product?.configurations?.relatedOrderNumber,
                paymentFrequency: product?.paymentInfo?.paymentFrequency,
                paymentType: product?.paymentInfo?.paymentType,
                installmentDuration: product?.configurations?.installmentDuration,
                billCharge: product?.configurations?.billCharge,
                isCombinedSaleInsurance: product?.isCombinedSale,
                vulnerabilityOver75: product?.configurations?.vulnerabilityOver75,
                vulnerabilitySocialBonus: product?.configurations?.vulnerabilitySocialBonus,
                vulnerabilitySaeMapre: product?.configurations?.vulnerabilitySaeMapre,
                vulnerabilityDisabled: product?.configurations?.vulnerabilityDisabled,
            },
            sku: product?.codeOrSku,
            uniqueProductCode: product?.codeOrSku,
            powerOrGas: product?.powerOrGas,
            addressType: product?.addressType,
            lineItemId: product?.lineItemId,
            status: product?.lineItemStatus,
            optionsConfig: [
                product?.configurations?.greenOption &&
                    `Opzione verde: ${product?.configurations?.greenOption ? 'Si' : 'No'}`,
                product?.configurations?.invoiceShippingMethod &&
                    (product?.configurations?.invoiceShippingMethod === AptBillType.Digitale
                        ? 'Bolletta digitale'
                        : 'Bolletta cartacea'),
            ].filter((config) => !!config),
            discountsPromo: product?.discountsPromo,
            privacyRequired: product?.privacyRequired,
            isExpired: product?.isAllowedDeferredSale,
            isAtNew: !!product?.productStatus,
            podPdr: product?.podPdr,
            supplyAddress: product?.deliveryAddress?.fullAddress,
            commAddress: product?.communicationAddress?.fullAddress,
            partnership: getString(product?.configurations?.partnership?.selected).toLowerCase() || null,
            supplyCode: product?.supplyCode,
        })),
        indirizzi: {
            indirizzoFornitura,
            indirizzoSpedizione: stessoIndirizzoSpedizione ? <Indirizzo>{} : indirizzoSpedizione,
            indirizzoComunicazioni: stessoIndirizzoComunicazioni ? <Indirizzo>{} : indirizzoComunicazioni,
            indirizzoResidenza: indirizzoResidenzaSedeLegale,
            //(stessoIndirizzoResidenza && indirizzoResidenzaSedeLegale) || <Indirizzo>{},
            stessoIndirizzoResidenza: stessoIndirizzoResidenza,
            stessoIndirizzoComunicazioni,
            stessoIndirizzoSpedizione,
            isSedeLegale,
            isResidenceAddressChanged: false, //TODO
        },
        datiPagamento: productToDatiPagamento(currentProduct),
        fotoDocumenti: dataV2?.fotoDocumenti
            ? {
                  ...dataV2?.fotoDocumenti,
                  rilasciatoIl: parseToISO8601DateOnly(dataV2?.fotoDocumenti?.rilasciatoIl) || null,
              }
            : null,
        realEstateOwnership: currentProduct?.configurations?.propertyOwnership,
        firma: dataV2?.firma,
        mp3Info: dataV2?.mp3Info,
        uploadPdfPlicoInfo: dataV2?.uploadPdfPlicoInfo,
        numeroPlico: dataV2?.numeroPlico,
        numeroDomiciliazione: currentProduct?.paymentInfo?.numeroDomiciliazione,
        tipoEsecuzione: tipoEsecuzioneV2ToV1(dataV2),
        fornitureAttive: dataV2?.fornitureEsistenti,
        selectFornitureAttiveSelezionate: (dataV2?.fornitureEsistenti || []).filter(
            ({ podPdr, stato }) => stato === 'ATTIVO' && !!wibBackProducts[podPdr],
        ),
        privacyTrattDatiPers: dataV2?.contact?.privacyTrattDatiPers,
        quoteStateModel: dataV2?.quoteStateModel,
        verificaContatto: dataV2?.verificaContatto,
        agencyBranchForMonitoring: dataV2?.agencyBranchForMonitoring,
        branchAgenziaAgente: dataV2?.branchAgenziaAgente,
        // Popolo i campi di winback solo per switchin
        ...(flowTypeUtil(dataV2?.flowType).inMacroFlowTypes(MacroFlowType.SwitchIn)
            ? {
                  isWinBack: !!currentProduct?.isWinBack,
                  winBackType: aptCommodityTypesToWinBackType(
                      currentProducts
                          .filter((product) => product?.isWinBack)
                          .map((product) => product?.isWinBack && product?.powerOrGas),
                  ),
              }
            : {}),
        ...getSkipPodPdrAteco(dataV2?.flowType, userState, currentProducts),
        ...(powerProduct
            ? {
                  typeOfUse: [].concat(powerProduct.technicalDetails?.typeOfUsage || []).join('|') || null,
                  potenzaImpegnataMb: isMicrobusiness ? <PotenzaImpegnataMB>cleanObj({
                            consumoInpt: powerProduct.technicalDetails?.consumption,
                            potenzaImpegnataCmb: getString(powerProduct.technicalDetails?.pwrInstantaneousPower),
                            potenzaDisponibileInp: getString(powerProduct.technicalDetails?.pwrAvailablePower),
                            livelloTensione: getString(powerProduct.technicalDetails?.pwrVoltage),
                            potenzaImpInputManuale: getString(
                                powerProduct.technicalDetails?.needed?.pwrInstantaneousPower,
                            ),
                        }) : null,
                  powerConsumption: {
                      value: powerProduct.technicalDetails?.consumption,
                      declared: powerProduct.technicalDetails?.pwrDeclaredConsumption,
                      transcodingValue: powerProduct.technicalDetails?.pwrTranscodedConsumption,
                  },
                  powerHomeDropdownOption: powerProduct.configurations?.homeTaxRelief,
                  potenzaImpegnata: getString(powerProduct.technicalDetails?.pwrInstantaneousPower) || null,
              }
            : {}),
        ...(gasProduct
            ? {
                  gasConsumption: product2GasConsumption(gasProduct),
                  gasHomeDropdownOption: gasProduct.configurations?.homeTaxRelief,
              }
            : {}),
        plicoUrl: dataV2?.plicoUrl,
        anagraficaMb: dataV2?.anagraficaMb?.piva
            ? {
                  ...dataV2?.anagraficaMb,
                  ...{
                      companyAddress: indirizzoHasValues(indirizzoResidenzaSedeLegale)
                          ? indirizzoResidenzaSedeLegale
                          : null,
                  },
              }
            : null,
        // Riprendere da qui
        atecoMb: isMicrobusiness
            ? {
                  codeAtecoPower: powerProduct?.businessDetails?.ateco,
                  codeAtecoGas: gasProduct?.businessDetails?.ateco,
                  validated: currentProduct?.businessDetails?.validated,
                  attivita: currentProduct?.businessDetails?.activity,
                  settore: currentProduct?.businessDetails?.sector,
                  denominazione: currentProduct?.businessDetails?.atecoDescription,
              }
            : null,
        ocrData: dataV2?.ocrData,

        fatturazioneElettronica: dataV2?.fatturazioneElettronica,

        impersonatedAgent: dataV2?.impersonatedAgent,
        invalidCf: dataV2?.invalidCf,
        creditCheckStatus: dataV2?.creditCheckStatus,
        deferredSaleEnabled: dataV2?.deferredSaleEnabled,
        responsabilitaCreditCheckModal: dataV2?.responsabilitaCreditCheckModal,
        termination: flowTypeUtil(dataV2?.flowType).inMacroFlowTypes(MacroFlowType.Cessazione)
            ? ({
                  cartId: localStorage.getItem('local-cart'),
                  isInvoiceDeliveryAddressUpdated:
                      dataV2.contact?.mainAddress !== false && !!dataV2.contact?.mainAddress?.isModified,
                  isMortisCausa: currentProduct?.isMortisCausa,
                  termDate: parseToISO8601DateOnly(currentProduct?.effectiveDate),
                  termContacts: [
                      {
                          oldContact: userState?.contact?.mobilephone,
                          confirmContact: dataV2?.contact?.phoneContactStatus === 'Confirmed',
                          changedContact: dataV2?.contact?.phoneContactStatus === 'Updated',
                      },
                      {
                          oldContact: userState?.contact?.emailaddress1,
                          confirmContact: dataV2?.contact?.mailContactStatus === 'Confirmed',
                          changedContact: dataV2?.contact?.mailContactStatus === 'Updated',
                      },
                  ],
                  termAppointment: appointmentV2ToAppointmentV1(
                      commodityProducts,
                      dataV2?.appointment,
                      userState.contact,
                  ),
                  termCosts: productsToTermCosts(currentProducts),
              } as Termination)
            : flowTypeUtil(dataV2?.flowType).inMacroFlowTypes(MacroFlowType.Voltura)
              ? ({
                    isMortisCausa: currentProduct?.isMortisCausa,
                } as Termination)
              : null,

        activation: !flowTypeUtil(dataV2?.flowType).inMacroFlowTypes(MacroFlowType.Attivazione)
            ? null
            : {
                  podActivationInfo: powerProduct && {
                      tensione: powerProduct?.technicalDetails?.pwrVoltage,
                      ultimaPotDisp: getString(powerProduct?.technicalDetails?.pwrInstantaneousPower) || null,
                      nuovaPotSel: getString(powerProduct?.technicalDetails?.needed?.pwrInstantaneousPower) || null,
                      fornitura: {
                          cap: powerProduct?.deliveryAddress?.cap,
                          civ: getCompleteCivic(powerProduct?.deliveryAddress),
                          istat:
                              getString(powerProduct?.deliveryAddress?.istatCodeProv).padStart(3, '0') +
                              getString(powerProduct?.deliveryAddress?.istatCodeMunicipality).padStart(3, '0'),
                          toponimo: powerProduct?.deliveryAddress?.toponym,
                          localita: powerProduct?.deliveryAddress?.municipality,
                          prov: powerProduct?.deliveryAddress?.shortProvince,
                          via: powerProduct?.deliveryAddress?.street,
                      },
                      identificativiRichiesta: {
                          pivaDistributore: powerProduct?.technicalDetails?.identificativiRichiesta?.pivaDistributore,
                      },
                  },
                  pdrActivationInfo: gasProduct && {
                      meterNumber: gasProduct?.technicalDetails?.meterSerialNumber,
                      potentiality: getString(gasProduct?.technicalDetails?.gasPotential),
                      meterClass: gasProduct?.technicalDetails?.gasMeterClass,
                      vendorCode: gasProduct?.technicalDetails?.vendorCode,
                      fornitura: {
                          CapPdrEsito: gasProduct?.deliveryAddress?.cap, // cap
                          CivicoPdrEsito: getCompleteCivic(gasProduct?.deliveryAddress), // number
                          ComunePdrEsito: gasProduct?.deliveryAddress?.municipality, //city
                          IndirPdrEsito: gasProduct?.deliveryAddress?.street, // street
                          IstatComunePdrEsito:
                              getString(gasProduct?.deliveryAddress?.istatCodeProv).padStart(3, '0') +
                              getString(gasProduct?.deliveryAddress?.istatCodeMunicipality).padStart(3, '0'),
                          PrPdrEsito: gasProduct?.deliveryAddress?.shortProvince, // provincia
                          SiglaTopPdrEsito: gasProduct?.deliveryAddress?.toponym, // Toponimo
                      },
                  },
                  propertyTypeSelected: currentProduct?.configurations?.propertyType,
                  actDate: currentProduct?.effectiveDate,
                  immediateEffect: currentProduct?.immediateEffect,
                  actiAppointment: appointmentV2ToAppointmentV1(
                      commodityProducts,
                      dataV2?.appointment,
                      userState.contact,
                  ),
              },
        costs: productsToCostData(currentProducts),

        selfCertification: dataV2?.selfCertification,
        ivassAnswers: dataV2?.ivassAnswers,
        insuranceDummyProductId: dataV2?.insuranceDummyProductId,
        partnership: currentProduct?.configurations?.partnership,
        quoteId: dataV2?.quoteId,
        combinedSaleQuoteId: dataV2?.linkedCommodity?.quoteId,
        combinedSale: dataV2?.linkedCommodity?.enabled,
        commodityCartId: dataV2?.linkedCommodity?.cartId,
        administrativeTechnicalData: {
            gas: gasProduct
                ? technicalDetailsToGasAdministrativeTechnicalData(gasProduct?.technicalDetails as GasTechnicalDetails)
                : null,
            power: powerProduct
                ? technicalDetailsToPowerAdministrativeTechnicalData(
                      powerProduct?.technicalDetails as PowerTechnicalDetails,
                  )
                : null,
        },
        domiciliationStandAlone: paymentInfoToDomiciliation(currentProducts),
        incident: dataV2?.incident,
        deliveryChannel: currentProduct?.configurations?.invoiceShippingMethod,
        requiredPower: getString(currentProduct?.technicalDetails?.pwrInstantaneousPower),
        availablePower: currentProduct?.technicalDetails?.pwrAvailablePower,
        voltage: currentProduct?.technicalDetails?.pwrVoltage,
        destinationUse: getValidDestinationUse(currentProduct?.configurations?.destinationUse),
        sendCommunications: null,
    };
    console.log('[STATE] orderEntryV2ToV1', stateV1);
    return stateV1;
}

export function appointmentV2ToAppointmentV1(
    products: Product[],
    orderAppointment: OrderAppointment,
    contact: UserContact,
): Appointment {
    return {
        presence: orderAppointment?.presence,
        altContactFName: orderAppointment?.firstName,
        altContactLName: orderAppointment?.lastName,
        altContactPrefix: orderAppointment?.prefix,
        altContactPhone: orderAppointment?.phone,
        timeSlot: orderAppointment?.timeslot,
        interphoneAvailable: orderAppointment?.interphone?.available,
        interphoneNotes: orderAppointment?.interphone?.notes,
        meters: products.map((product) => ({
            lineItemId: product?.lineItemId,
            isAvailable: product?.appointment?.available,
            location: product?.appointment?.location,
            notes: product?.appointment?.notes,
        })),
        csv: products.map(({ lineItemId, deliveryAddress, appointment }) => ({
            lineItemId,
            content: createCSV(orderAppointment, appointment, deliveryAddress?.fullAddress, {
                phone: contact?.mobilephone,
                prefix: contact?.prefixMobilephone,
            }),
        })),
    };
}

export function product2GasConsumption(gasProduct: Product): GasConsumption {
    return {
        typeOfUse: [].concat(gasProduct.technicalDetails?.typeOfUsage || []).join('|') || null,
        consumption: gasProduct.technicalDetails?.consumption,
        selfReading: {
            meterCounter:
                gasProduct.technicalDetails?.needed?.meterCounter || gasProduct.technicalDetails?.meterCounter,
            meterCounterValidity: !!gasProduct.technicalDetails?.meterCounter,
            meterAdjustmentCounter: gasProduct.technicalDetails?.gasMeterAdjustmentNumber,
        },
    };
}

export function paymentInfoToDomiciliation(products: Product[]): Domiciliation {
    const defaultProduct = products.find((product) => product?.paymentInfo?.paymentTool);
    const assets = (products || [])
        .filter((product) => !!product?.assetId)
        .map((product) => ({
            id: product?.assetId,
            productName: product?.name,
            productType: product?.productType,
            indirizzo: product?.deliveryAddress?.fullAddress,
            pod: product?.powerOrGas === AptCommodityType.Power && product?.podPdr,
            pdr: product?.powerOrGas === AptCommodityType.Gas && product?.podPdr,
            status: product?.lineItemStatus,
        }));
    const domiciliation = cleanObj<Domiciliation>({
        id: defaultProduct?.paymentInfo?.paymentTool?.id,
        oldId: defaultProduct?.paymentInfo?.paymentTool?.oldId,
        deactivateOldPaymentTool: defaultProduct?.paymentInfo?.paymentTool?.deactivateOldPaymentTool,
        billingPreferenceId: defaultProduct?.paymentInfo?.paymentTool?.billingPreferenceCode,
        assets: assets.length ? assets : null,
        caseID: defaultProduct?.paymentInfo?.paymentTool?.caseID,
    });
    return Object.keys(domiciliation).length ? (domiciliation as Domiciliation) : null;
}

export function technicalDetailsToPowerAdministrativeTechnicalData(
    technicalDetails: PowerTechnicalDetails,
): PowerAdministrativeTechnicalData {
    return technicalDetails
        ? {
              potenzaDisponibile: technicalDetails?.needed?.pwrAvailablePower || technicalDetails?.pwrAvailablePower,
              potenzaImpegnata:
                  technicalDetails?.needed?.pwrInstantaneousPower || technicalDetails?.pwrInstantaneousPower,
              tensione: technicalDetails?.needed?.pwrVoltage || technicalDetails?.pwrVoltage,
          }
        : null;
}

export function technicalDetailsToGasAdministrativeTechnicalData(
    technicalDetails: GasTechnicalDetails,
): GasAdministrativeTechnicalData {
    return technicalDetails
        ? {
              tipologiaPdr: technicalDetails?.gasMeterScope,
              matricolaMisuratore: technicalDetails?.meterSerialNumber,
              cifreMisuratore: technicalDetails?.gasMeterDigits,
              letturaMisuratore: technicalDetails?.meterCounter,
              classeMisuratore: technicalDetails?.gasMeterClass,
              gruppoMisuratoreIntegrato: technicalDetails?.gasMeterEmbeddedCluster,
              coefficienteCorrettivoC: technicalDetails?.gasMeterC2AdjustmentFactor,
              cifreCorrettore: technicalDetails?.gasMeterAdjustmentDigit,
              matricolaCorrettore: technicalDetails?.gasMeterAdjustmentSerialNumber,
              letturaCorrettore: technicalDetails?.gasMeterAdjustmentNumber,
              cityGate: technicalDetails?.gasCityGate,
              classeDiPrelievo: technicalDetails?.gasWithdrawalClass,
              progressivoVolumiAnnuo: technicalDetails?.gasProgressiveAnnualVolumes,
              istatComune: technicalDetails?.gasIstatCommon,
              pIvaDistributore: technicalDetails?.gasDistributorTaxCode,
          }
        : null;
}

export function productsToCostData(products: Product[]): CostData {
    const records = (products || [])
        .filter(({ lineItemId }) => !!lineItemId)
        .map(({ lineItemId, prices }) => ({
            id: lineItemId,
            otherCosts: prices?.sdr,
            eniCosts: prices?.ops,
            total: sumPrices(prices?.sdr, prices?.ops),
        }));
    return {
        records,
        total: records.reduce((grandTot, { total }) => sumPrices(grandTot, total), 0),
        skipCosts: (products || []).some((product) => product?.prices?.skip),
    };
}

export function productsToTermCosts(products: Product[]): TermCosts[] {
    return (products || [])
        .filter(({ lineItemId }) => !!lineItemId)
        .map(({ lineItemId, prices }) => ({
            idAsset: lineItemId,
            priceSDR: getString(prices?.sdr),
            priceOPS: getString(prices?.ops),
        }));
}

export function productToDatiPagamento(product: Product): FormDatiPagamento {
    return Object.assign(new FormDatiPagamento(), {
        id: product?.paymentInfo?.paymentTool?.id,
        oldId: product?.paymentInfo?.paymentTool?.oldId,
        tipoPagamento: product?.paymentInfo?.paymentInstrument,
        existingPaymentTool: product?.paymentInfo?.existingPaymentTool,
        creditCardExpirationDate: product?.paymentInfo?.paymentTool?.creditCardExpirationDate,
        deactivateOldPaymentTool: product?.paymentInfo?.paymentTool?.deactivateOldPaymentTool,
        formBonifico: Object.assign(
            new FormBonifico(),
            product?.paymentInfo?.paymentInstrument === AptPaymentInstrument.AddebitoCC
                ? {
                      intestatarioConto: product?.paymentInfo?.paymentTool?.bankAccountOwner,
                      iban: product?.paymentInfo?.paymentTool?.iban,
                      settoreAppartenenza: product?.paymentInfo?.paymentTool?.membership,
                      isIbanEstero:
                          !!getString(product?.paymentInfo?.paymentTool?.iban).trim() &&
                          !isIbanItalian(product?.paymentInfo?.paymentTool?.iban),
                      titolareConto: companyOrPersonalIdentityToTitolareConto(
                          product?.paymentInfo?.paymentTool?.holder,
                      ),
                      sottoscrittoreSepa: Object.assign(new SottoscrittoreSepa(), {
                          cf: product?.paymentInfo?.paymentTool?.sepaSubscriber?.fiscalCode,
                          nome: product?.paymentInfo?.paymentTool?.sepaSubscriber?.firstName,
                          cognome: product?.paymentInfo?.paymentTool?.sepaSubscriber?.lastName,
                      }),
                  }
                : {},
        ),
    });
}

function companyOrPersonalIdentityToTitolareConto(holder: PersonalIdentity): TitolareConto;
function companyOrPersonalIdentityToTitolareConto(holder: CompanyIdentity): TitolareConto;
function companyOrPersonalIdentityToTitolareConto(holder: CompanyIdentity & PersonalIdentity): TitolareConto {
    return Object.assign(new TitolareConto(), {
        ragioneSociale: holder?.companyName,
        piva: holder?.vatCode,
        cfAzienda: holder?.fiscalCode,
        //tipologia: holder?.,
        cf: holder?.fiscalCode,
        nome: holder?.firstName,
        cognome: holder?.lastName,
    });
}

function indirizzoHasValues(input: Indirizzo): boolean {
    return !!(input?.codiceEgon || input?.codiceEgonStreet || input?.via || input?.comune);
}

function getSkipPodPdrAteco(
    flowType: FlowType,
    userState: UserState,
    currentProducts: Product[],
): {
    skipPod: boolean;
    skipPdr: boolean;
    skipAteco: boolean;
} {
    const flowNotAllowSkip = flowTypeUtil(flowType).inMacroFlowTypes(
        MacroFlowType.Cessazione,
        MacroFlowType.Variazione,
        MacroFlowType.Domiciliazione,
        MacroFlowType.CambioProdotto,
    );

    return currentProducts?.reduce(
        (aggr, curr) =>
            flowNotAllowSkip
                ? aggr
                : {
                      skipPod: aggr.skipPod || (isPowerProduct(curr) && isCommodityFamily(curr.family) && !curr.podPdr),
                      skipPdr: aggr.skipPod || (isGasProduct(curr) && isCommodityFamily(curr.family) && !curr.podPdr),
                      skipAteco:
                          aggr.skipAteco ||
                          (userState?.cartSegment === D365CustomerSegment.Microbusiness &&
                              !curr?.businessDetails?.ateco),
                  },
        {
            skipPod: false,
            skipPdr: false,
            skipAteco: false,
        },
    );
}

function tipoEsecuzioneV2ToV1(dataV2: OrderEntryState_v2) {
    const productCommodity = (dataV2?.products || []).find(
        (product) => isCommodityFamily(product?.family) && !!product?.effectiveDate,
    );
    const productMaintenance = (dataV2?.products || []).find(
        (product) => product?.family === AptProductFamily.ServizioTecnico && !!product?.effectiveDate,
    );

    return {
        dataAnticipataCommodity: dataV2?.tipoEsecuzione?.dataAnticipataCommodity,
        dataStandardCommodity: dataV2?.tipoEsecuzione?.dataStandardCommodity,
        isRataFissaCP: dataV2?.tipoEsecuzione?.isRataFissaCP,
        isRataFissaDatesEquals: dataV2?.tipoEsecuzione?.isRataFissaDatesEquals,
        dataStimataCommodity: productCommodity?.effectiveDate,
        dataStimataManutenzione: productMaintenance?.effectiveDate,
        passaggioRapidoCommodity: productCommodity?.immediateEffect,
        passaggioRapidoManutenzione: productMaintenance?.immediateEffect,
    };
}
