import { HistoryItem } from './../models/get-quote-order-history.response';
import { FilterCommodityType } from './../../../common/enums/apttus/apt-commodity-typeof-sale';
import { ResubmitOrderResponse } from './../models/resubmit-order';
import { OrderActions, OrderActionsResponse, OrderLineItemAction } from './../models/order-action';
import { AzioneBo } from './../models/azione-bo';
import { BoAgentFilter } from './../models/bo-agent-filter';
import { TranslateService } from '@ngx-translate/core';
import { CheckAttributeItem } from './../../../common/models/apex-rest/checkattribute-response';
import { BaseApexApiResponse } from './../../../common/interfaces/base-apex-api-respose';
import { AptSalesProcess } from './../../../common/enums/apttus/apt-sales-process';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { UploadZipRecordingsResponse } from '../models/upload-zip-recordings-response';
import { UploadZipRecordingsRequest } from '../models/upload-zip-recordings-request';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { of, forkJoin, Observable, throwError } from 'rxjs';
import { RetrieveFileInfoResponse } from '../models/retrieve-file-info.response';
import { RecuperoDocumentazioneRequest } from '../models/recupero-documentazione-request';
import { ApttusDoOperationRequest, ApttusDoOperationResponse } from '../../../common/models/apex-rest/doi-resubmission';
import { AptOperationType } from '../../../common/enums/apttus/apt-operation-type';
import { DoActionBody } from '../models/do-action-body';
import { SfaOfferingRetrieveRequest } from '../models/sfa_offering-retrieve-request';
import { SfaOfferingRetrieveResponse } from '../models/sfa_offering-retrieve-response';
import { RetrieveAttachmentListRequest } from '../models/retrieve-attachment-list.request';
import { RetrieveAttachmentRequest } from '../models/retrieve-attachment.request';
import { ReplaceAttachmentRequest } from '../models/replace-attachment.request';
import { RetrieveAttachmentListResponse } from '../models/retrieve-attachment-list.response';
import { BaseApiResponse, StatusResponse } from '../../../common/interfaces/base-api-response';
import { DownloadListExcelRequest } from '../models/download-list-excel.request';
import { MonitoraggioFilters } from '../models/monitoraggio-filter-list';
import * as moment from 'moment';
import { ManageQuoteRequest } from '../models/manage-quote.request';
import { ManageQuoteResponse } from '../models/manage-quote.response';
import { BoActionFilter } from '../../../common/enums/shared/bo-action-filter';
import { GestioneFilters } from '../models/gestione-filter-list';
import { ActionFilterResponse } from '../models/action-filter-response';
import { ApiService } from '../../../common/services/shared/api.service';
import { UploadResponseObservable } from '../../../common/models/app/upload-response-observable';
import { D365ProfileCode } from '../../../common/enums/d365/d365-profile-code';
import { D365ChannelCode } from '../../../common/enums/d365/d365-channel-code';
import { MonitoraggioQuoteRequest } from '../models/monitoraggio-quote.request';
import { MonitoraggioQuoteResponse } from '../models/monitoraggio-quote.response';
import { AptQuoteStatus } from '../../../common/enums/apttus/apt-quote-status';
import { ApexApi, ApiMngApi, BaseProvider } from '../../../common/providers/base-provider';
import { DeleteAttachmentRequest } from '../models/delete-attachment';
import { UploadIdentityCardRequest } from '../../common/order-entry/steps/06-firma/models/upload-identity-card.request';
import { UtilityService } from '../../../common/services/shared/utility.service';
import { BoCommonFilters } from '../models/bo-common-filters';
import { PrivateConfigurationService } from '../../../common/services/shared/private-configuration.service';
import { ChangeCartOwnerResponse } from '../models/change-cart-owner-response';
import { QuoteDetail, QuoteDetailResponse } from '../models/quotedetailresponse-response';
import { CombinedSalesItemsResponse } from '../models/combined-sales-response';
import { LoggerService } from '../../../common/services/shared/logger.service';
import { PaymentDetail, PaymentDetailResponse } from '../models/paymentdetailresponse-response';
import { QuoteLineItemDetail, QuoteLineItemDetailResponse } from '../models/quotelinetemdetailresponse-response';
import { OrderDetail, OrderDetailResponse, OrderLineItemDetail } from '../models/order-details';
import { MonitoraggioOrderResponse } from '../models/monitoraggio-order.response';
import { MonitoraggioOrderRequest } from '../models/monitoraggio-order.request';
import { AptOrderStatus } from '../../../common/enums/apttus/apt-order-status';
import { NO_LOADING } from '../../../common/services/shared/http-request-interceptor.service';
import { StatusApexResponse } from '../../../common/interfaces/base-apex-api-respose';
import { ReworkRequest } from '../models/rework.request';
import { ReworkResponse, ReworkStatusResponse } from '../models/rework.response';
import {
    OrderLineItemDetailExtended,
    OrderLineItemDetailResponse,
} from '../models/orderlinetemdetailresponse-response';
import {
    DeleteDetailOrderRequest,
    DeleteDetailOrderResponse,
    FieldToChange,
    OrderLine,
} from '../models/delete-detail-order';
import { Orders, Record, RevocationOrderRequest, RevocationOrderResponse } from '../models/revocation-order';
import { Store } from '@ngrx/store';
import { EglState } from '../../../store/reducers';
import { selectAgentInfo } from '../../../store/selectors/user.selectors';
import { GetQuoteOrderHistoryResponse } from '../models/get-quote-order-history.response';
import { GetQuoteOrderHistoryRequest, HistoryObjectType } from '../models/get-quote-order-history.request';
import { DdlRequest } from '../models/ddl.request';
import { DdlResponse } from '../models/ddl.response';
import { DownloadListExcelResponse } from '../models/download-list-excel.response';
import { MorosityErrorCode } from '../../morosity/models/morosity.error';
import { CheckCallToCheckDOIRequest, CheckCallToCheckDOIResponse } from '../models/checkCall-to-checkDOI';
import { CancelApttusQuoteRequest, CancelApttusQuoteResponse } from '../models/cancel-apttus-quote';
import { BaseTibcoRequest } from '../../switch-in/order-entry/models/tibco-base-request';
import { AptOrderLineStatus } from '../../../common/enums/apttus/apt-order-line-status';
import { GetAssetReportViewsRequest } from '../models/get-asset-report-views.request';
import { AssetReportAssetItem, AssetReportAssetViewResponse } from '../models/get-asset-report-asset-view.response';
import { AssetReportOtiView } from '../models/get-asset-report-oti-view.response';
import { AssetReportOliView, AssetReportOliViewResponse } from '../models/get-asset-report-oli-view.response';
import { inadmissibilitySalesProcessToInadmissibilityFilter } from '../../../common/functions/remap.functions';
import { InadmissibilityFilter } from '../../../common/models/app/inadmissibility-filter';
import { ChangeEffectiveDateRequest } from '../models/change-effective-date';
import { BoDateTypeFilterVal } from '../../../common/enums/shared/bo-date-filter-val';
import { BOType } from '../common/configuration/bo-table-search-fields.config';
import { CustomerEngagementResponse } from '../models/bo-customer-engagement.';
import { BoFilterByDate } from '../models/bo-filter-by-date';

@Injectable()
export class BackOfficeProvider extends BaseProvider {
    private dateFromFormat = 'YYYY-MM-DD 00:00:00';
    private dateToFormat = 'YYYY-MM-DD 23:59:59';
    constructor(
        private api: ApiService,
        private utilitySrv: UtilityService,
        private store: Store<EglState>,
        private loggerService: LoggerService,
        protected configSrv: PrivateConfigurationService,
        translateService: TranslateService
    ) {
        super(configSrv, translateService);
    }

    uploadZipRecordingsObservable(
        req: UploadZipRecordingsRequest
    ): Observable<UploadResponseObservable<UploadZipRecordingsResponse>> {
        const jsonBody = {
            FileName: req.FileName,
            SkipValidazioneBO: req.SkipValidazioneBO,
        };
        const formData = new FormData();
        formData.append('File', req.File);
        formData.append('JsonBody', JSON.stringify(jsonBody));

        const url = this.getApiMngApiUrl(ApiMngApi.UploadZipRecordings);

        return this.api.httpClientInstance
            .post<any>(url, formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            const progress = Math.round((100 * event.loaded) / event.total);
                            return {
                                inProgress: true,
                                inError: false,
                                percent: progress,
                                bodyResponse: null,
                                errorMessage: '',
                            };

                        case HttpEventType.Response:
                            return {
                                inProgress: true,
                                inError: false,
                                percent: 100,
                                bodyResponse: event.body,
                                errorMessage: '',
                            };
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    return of({
                        inProgress: false,
                        inError: true,
                        percent: null,
                        bodyResponse: null,
                        errorMessage: error?.message,
                    });
                })
            );
    }

    recuperoDocumentazione(
        request: RecuperoDocumentazioneRequest
    ): Observable<BaseApiResponse<RetrieveFileInfoResponse>> {
        return this.api
            .postAsync<BaseApiResponse<RetrieveFileInfoResponse>>(
                this.getApiMngApiUrl(ApiMngApi.RecuperaDocumentazione),
                request
            )
            .pipe(catchError(() => of(null)));
    }

    async sfaOfferingRetrieve(codicePlico: string): Promise<BaseApiResponse<SfaOfferingRetrieveResponse>> {
        const body: SfaOfferingRetrieveRequest = {
            codicePlico: codicePlico,
            InfoLog: {},
        };
        return this.api
            .postAsync<BaseApiResponse<SfaOfferingRetrieveResponse>>(
                this.getApiMngApiUrl(ApiMngApi.SfaOfferingRetrieve),
                body
            )
            .toPromise()
            .catch(() => null);
    }

    checkCallToCheckDOI(quoteId: string): Observable<void> {
        const body: CheckCallToCheckDOIRequest = {
            quoteId,
            InfoLog: new BaseTibcoRequest().InfoLog,
        };
        return this.api
            .postAsync<BaseApiResponse<CheckCallToCheckDOIResponse> | any>(
                this.getApiMngApiUrl(ApiMngApi.CheckCallToCheckDOI),
                body
            )
            .pipe(
                tap((response) => {
                    if (response?.result !== '001') {
                        if (response?.errorManagement?.errorCode) {
                            throw new ApimError(
                                response?.errorManagement?.errorDescription || 'Si è verificato un problema',
                                response?.errorManagement?.errorCode
                            );
                        }
                        throw new Error(response?.errorManagement?.errorDescription || 'Si è verificato un problema');
                    }
                }),
                map(() => null)
            );
    }

    cancelApttusQuote(quoteId: string, body?: DoActionBody): Observable<void> {
        const bodyData: CancelApttusQuoteRequest = {
            quoteId,
            InfoLog: new BaseTibcoRequest().InfoLog,
            notes: body.Notes,
            reason: body.Reason,
            welcomeCall: body.WelcomeCall || false,
        };
        return this.api
            .postAsync<BaseApiResponse<CancelApttusQuoteResponse>>(
                this.getApiMngApiUrl(ApiMngApi.CancelApttusQuote),
                bodyData
            )
            .pipe(
                tap((response) => {
                    if (response?.result !== '001') {
                        if (response?.errorManagement?.errorCode) {
                            throw new ApimError(
                                response?.errorManagement?.errorDescription || 'Si è verificato un problema',
                                response?.errorManagement?.errorCode
                            );
                        }
                        throw new Error(response?.errorManagement?.errorDescription || 'Si è verificato un problema');
                    }
                }),
                map(() => null)
            );
    }

    rejectApttusQuote(quoteId: string, body?: DoActionBody): Observable<void> {
        const bodyData: CancelApttusQuoteRequest = {
            quoteId,
            InfoLog: new BaseTibcoRequest().InfoLog,
            notes: body.Notes,
            reason: body.Reason,
            reasonType: body.ReasonType,
            welcomeCall: body.WelcomeCall || false,
        };
        return this.api
            .postAsync<BaseApiResponse<CancelApttusQuoteResponse>>(
                this.getApiMngApiUrl(ApiMngApi.RejectApttusQuote),
                bodyData
            )
            .pipe(
                tap((response) => {
                    if (response?.result !== '001') {
                        if (response?.errorManagement?.errorCode) {
                            throw new ApimError(
                                response?.errorManagement?.errorDescription || 'Si è verificato un problema',
                                response?.errorManagement?.errorCode
                            );
                        }
                        throw new Error(response?.errorManagement?.errorDescription || 'Si è verificato un problema');
                    }
                }),
                map(() => null)
            );
    }

    doApttusAction(
        quoteId: string,
        operationType: AptOperationType | string,
        params?: DoActionBody
    ): Observable<ApttusDoOperationResponse> {
        const body = new ApttusDoOperationRequest(quoteId, operationType, params);
        return this.api.postAsync<ApttusDoOperationResponse>(this.getApexApiUrl(ApexApi.DoApttusAction), body).pipe(
            catchError((err) => {
                this.loggerService.error(null, 'Errore nella chiamata al servizio doApttusAction', err, false);
                return of(null);
            })
        );
    }

    /**
     * Effettua una chiamata ad un servizio che permette la lista di allegati relativi al codice plico in input.
     * @param request oggetto contenente il codice plico necessario per effettuare la chiamata.
     */
    async retrieveAttachmentList(
        request: RetrieveAttachmentListRequest
    ): Promise<BaseApiResponse<RetrieveAttachmentListResponse>> {
        return await this.api
            .postAsync<BaseApiResponse<RetrieveAttachmentListResponse>>(
                this.getApiMngApiUrl(ApiMngApi.RetrieveAttachmentList),
                request,
                undefined,
                NO_LOADING
            )
            .toPromise();
    }

    /**
     * Effettua una chiamata ad un servizio che permette di ottenere l' allegato richiesto tramite request in input.
     * @param request oggetto contenente il dettaglio dell'allegato necessario per effettuare la chiamata.
     */
    async retrieveAttachment(request: RetrieveAttachmentRequest): Promise<BaseApiResponse<RetrieveFileInfoResponse>> {
        return await this.api
            .postAsync<BaseApiResponse<RetrieveFileInfoResponse>>(
                this.getApiMngApiUrl(ApiMngApi.RetrieveAttachment),
                request
            )
            .toPromise();
    }

    /**
     * Effettua una chiamata ad un servizio che permette la sostituzione di un allegato con un altro in input.
     * @param request oggetto contenente il file necessario per effettuare la chiamata.
     */
    async replaceAttachmentBO(
        oldName: string,
        oldExtension: string,
        attachmentType: string,
        newFile: File,
        codicePlico: string,
        codiceRds: string,
        quoteId: string
    ): Promise<BaseApiResponse<string>> {
        const req = new ReplaceAttachmentRequest();
        req.File = newFile;
        req.JsonBody = {
            FileName: oldName,
            codicePlico: codicePlico,
            flowType: 'BACKOFFICE',
            codiceRds: codiceRds,
            tipoAllegato: attachmentType,
            originalExtension: oldExtension,
            quoteId: quoteId,
        };

        const formData = new FormData();
        formData.append('File', req.File);
        formData.append('JsonBody', JSON.stringify(req.JsonBody));

        try {
            return await this.api
                .postAsync<BaseApiResponse<string>>(this.getApiMngApiUrl(ApiMngApi.ReplaceAttachmentBO), formData)
                .toPromise();
        } catch (e) {
            return e.error as BaseApiResponse<string>;
        }
    }

    async uploadIdentityCardBO(req: UploadIdentityCardRequest): Promise<BaseApiResponse<string>> {
        if (!req && !req.NumeroPlico) {
            const res = new BaseApiResponse<string>();
            res.status = StatusResponse.Failed;
            return res;
        }
        const jBody = {
            FileNameFronte: '',
            FileNameRetro: '',
        };
        const formData = new FormData();
        if (req.Fronte) {
            const tmpFronte = this.utilitySrv.renameFile(req.Fronte, `${req.NumeroPlico}_F`, true);
            formData.append('Fronte', tmpFronte);
            jBody.FileNameFronte = tmpFronte.name;
        }
        if (req.Retro) {
            const tmpRetro = this.utilitySrv.renameFile(req.Retro, `${req.NumeroPlico}_R`, true);
            formData.append('Retro', tmpRetro);
            jBody.FileNameRetro = tmpRetro.name;
        }
        formData.append('QuoteID', req.QuoteID);
        formData.append('jsonBody', JSON.stringify(jBody));
        return this.api
            .postAsync<BaseApiResponse<string>>(this.getApiMngApiUrl(ApiMngApi.UploadIdentityCard), formData)
            .toPromise()
            .catch(() => null);
    }

    /**
     * Effettua una chiamata ad un servizio che elimina un allegato.
     * @param request oggetto contenente il nome dell'allegato da eliminare ed il codice plico associato.
     */

    async deleteDocumentazione(request: DeleteAttachmentRequest) {
        try {
            return await this.api
                .postAsync<BaseApiResponse<string>>(this.getApiMngApiUrl(ApiMngApi.DeleteDocumentazione), request)
                .toPromise();
        } catch (e) {
            return e.error as BaseApiResponse<string>;
        }
    }

    deleteOrder(idItemsSelected: string[], isViewDetail: boolean, reason?: string) {
        const tasksObservables = idItemsSelected.map((orderId) => {
            const req: DeleteDetailOrderRequest = {
                orderId: orderId,
                reason: reason,
            };
            return this.api.postAsync<DeleteDetailOrderResponse>(
                this.getApexApiUrl(ApexApi.DeleteOrder),
                req,
                0,
                isViewDetail ? {} : NO_LOADING
            );
        });
        return forkJoin(tasksObservables);
    }

    deleteDetailOrder(
        orderId: string,
        listOliSelected: OrderLineItemDetail[],
        reason?: string
    ): Observable<DeleteDetailOrderResponse> {
        const request: DeleteDetailOrderRequest = {
            orderId: orderId,
            orderLines: listOliSelected.map(
                (oli) =>
                    ({
                        orderLineId: oli.id,
                        fieldToChange: [
                            {
                                apiname: 'Apttus_Config2__Status__c',
                                value: AptOrderLineStatus.PendingCancellation,
                            } as FieldToChange,
                        ],
                    } as OrderLine)
            ),
        };

        if (!!reason) {
            request.orderLines.forEach((x) => {
                x.fieldToChange.push({
                    apiname: 'egl_reasonKo__c',
                    value: reason,
                } as FieldToChange);
            });
        }

        return this.api.postAsync<DeleteDetailOrderResponse>(this.getApexApiUrl(ApexApi.AmendOrder), request);
    }

    revocationOrder(
        items: { billingAccount: string; id: string; assetId: string; pdf: string }[],
        isViewDetail: boolean,
        revocationReason?: string,
        resumeType?: string
    ): Observable<RevocationOrderResponse[]> {
        const opts = {
            headers: {
                ...(!isViewDetail && { ['no-loading']: 'true' }),
            },
        };

        const tasksObservables = items.map((oli) => {
            const request: RevocationOrderRequest = {
                Orders: {
                    totalSize: 1,
                    records: [
                        {
                            ServiceItem: '2',
                            Pdf: oli.pdf || 'Default PDF',
                            CodiceContoCliente: oli.billingAccount || '000000000000',
                            MotivoRevoca: revocationReason || '',
                            StazioneWF: '1',
                            OrderLineItemId: oli.id,
                            AssetId: oli.assetId,
                            ...(resumeType && {
                                ResumeType: resumeType,
                            }),
                        },
                    ] as Record[],
                } as Orders,
            };
            return this.api.postAsync<RevocationOrderResponse>(
                this.getApiMngApiUrl(ApiMngApi.Revocation),
                request,
                0,
                opts
            );
        });
        return forkJoin(tasksObservables);
    }

    getDdlData(oliId: string, channelDdl: string): Observable<DdlResponse> {
        return this.api.postAsync<DdlResponse>(this.getApiMngApiUrl(ApiMngApi.GetDdlData), <DdlRequest>{
            channel: channelDdl,
            oliId: oliId,
        });
    }

    revocationOrderSuccessCallback(CodiceEsito: string): boolean {
        return ['OK', MorosityErrorCode.Ok].includes(CodiceEsito?.toLocaleUpperCase());
    }

    reworkService(reqReworkService: ReworkRequest): Observable<ReworkResponse> {
        return this.api.postAsync<ReworkResponse>(this.getApexApiUrl(ApexApi.Rework), reqReworkService);
    }

    async downloadListExcel(
        fs: MonitoraggioFilters,
        view: BOType,
        profile: string
    ): Promise<BaseApiResponse<DownloadListExcelResponse>> {
        try {
            const req = new DownloadListExcelRequest(view, profile);
            req.Filtri.Stati = fs.conds?.status || fs.conds?.statusOrder || [];

            if (fs?.inamissibilityMode) {
                const inadmissibilityFilter = inadmissibilitySalesProcessToInadmissibilityFilter(
                    fs?.inamissibilityMode
                );
                req.Filtri.type = inadmissibilityFilter.type;

                if (inadmissibilityFilter.subType) {
                    req.Filtri.subType = inadmissibilityFilter.subType;
                }
            }

            req.Filtri.Data.dataDa = moment(fs?.conds?.date?.dateStart || new Date(2019, 1, 1)).format(
                this.dateFromFormat
            );
            req.Filtri.Data.dataA = moment(fs?.conds?.date?.dateEnd || new Date()).format(this.dateToFormat);
            req.Filtri.Data.tipodata = fs?.conds?.date?.type || BoDateTypeFilterVal.DataCreazione;

            // set order filters (default is DESC for CreatedDate)
            const sort = fs?.conds?.orderBy?.split('|');
            req.Filtri.sortByField = sort ? sort[0] : 'CreatedDate';
            req.Filtri.sortByType = sort ? (sort[1] as 'ASC' | 'DESC') : 'DESC';
            req.Filtri.searchKey = fs?.conds?.searchByField?.field;
            req.Filtri.searchValue = fs?.conds?.searchByField?.value;

            req.Filtri.salesProcess = fs?.operativeMode;
            req.Filtri.commodityType = fs?.commodityType;

            this.manageCommonBoFilter(fs?.conds, req);

            return await this.api
                .postAsync<BaseApiResponse<DownloadListExcelResponse>>(
                    this.getApiMngApiUrl(ApiMngApi.DownloadMonitorCSV),
                    req
                )
                .toPromise();
        } catch (error) {
            return null;
        }
    }

    /**
     * Restituisce la lista delle quote con le relative actions disponibili
     * @param skip offset per paginazione
     * @param take numero di record da restituire
     * @param profilo profilo utente
     * @param canale canale di vendita
     * @param fs condizioni di filtraggio
     * @param commonConds condizioni di filtraggio agente e sede operativa
     */
    async manageQuoteList(
        skip: number,
        take: number,
        profilo: D365ProfileCode,
        canale: D365ChannelCode,
        fs: GestioneFilters,
        commonConds: BoCommonFilters
    ): Promise<ManageQuoteResponse> {
        try {
            const req = new ManageQuoteRequest(skip, take, profilo);

            if (fs?.conds?.actions) {
                req.Filtri.action = fs.conds.actions as BoActionFilter[];
            }
            this.manageFilterDates(req, fs?.conds?.date);
            if (fs?.conds?.orderBy) {
                const sort = fs.conds.orderBy.split('|');
                req.Filtri.sortByField = sort[0];
                req.Filtri.sortByType = sort[1] as 'ASC' | 'DESC';
            }

            req.Filtri.salesProcess = fs?.operativeMode;

            this.manageCommonBoFilter(commonConds, req, canale);

            return new Promise<ManageQuoteResponse>(async (resolve) => {
                let result: ManageQuoteResponse = null;
                let pageCount = 1;
                const pageSize = 100;

                for (let i = 0; i < pageCount; i++) {
                    req.Filtri.skip = i * pageSize;
                    req.Filtri.take = pageSize;

                    const tRes = await this.api
                        .postAsync<ManageQuoteResponse>(
                            this.getApexApiUrl(ApexApi.ManageQuoteList),
                            req,
                            undefined,
                            NO_LOADING
                        )
                        .toPromise();

                    tRes.Quote = tRes.Quote || [];
                    if (tRes?.totalCount > 0) pageCount = Math.min(Math.round(tRes.totalCount / pageSize), 6);
                    if (tRes.status == StatusApexResponse.Failed) pageCount = i;

                    if (result == null) {
                        result = { ...tRes, totalCount: 0 };
                    } else {
                        result.Quote = result.Quote.concat(tRes.Quote);
                    }

                    result.totalCount += tRes.Quote.length;
                }
                result.status = StatusApexResponse.Success;
                resolve(result);
            });
        } catch (error) {
            return null;
        }
    }

    /**
     * Restituisce la lista delle quote per il tab Monitoraggio
     * @param itemsSkip offset per paginazione
     * @param itemsTake numero di record da restituire
     * @param fs condizioni di filtraggio
     * @param commonConds condizioni di filtraggio agente e sede operativa
     */
    monitoraggioQuoteList(
        itemsSkip: number,
        itemsTake: number,
        fs: MonitoraggioFilters,
        commonConds: BoCommonFilters
    ): Observable<MonitoraggioQuoteResponse> {
        const req = new MonitoraggioQuoteRequest(itemsSkip, itemsTake);
        if (fs?.conds?.searchByField) {
            req.Filtri.generic = {
                key: fs.conds.searchByField.field,
                value: fs.conds.searchByField.value,
            };
        }

        if (fs?.conds?.status) {
            req.Filtri.status = fs.conds.status as AptQuoteStatus[];
        }
        if (fs?.conds?.substatus) {
            req.Filtri.substatus = fs.conds.substatus;
            if (fs?.conds?.searchByField?.field && fs?.conds?.searchByField?.value) {
                req.Filtri.status = [];
                req.Filtri.substatus = [];
            }
        }
        this.manageFilterDates(req, fs?.conds?.date);

        if (fs?.conds?.orderBy) {
            const sort = fs.conds.orderBy.split('|');
            req.Filtri.sortByField = sort[0];
            req.Filtri.sortByType = sort[1] as 'ASC' | 'DESC';
        }

        req.Filtri.salesProcess = fs?.operativeMode;
        this.manageCommonBoFilter(commonConds, req);

        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((c) => {
                req.Obfuscate = c.ShouldObfuscateDataInBackOffice;
                return this.api
                    .postAsync<MonitoraggioQuoteResponse>(
                        this.getApexApiUrl(ApexApi.MonitorQuote),
                        req,
                        undefined,
                        NO_LOADING
                    )
                    .pipe(
                        mergeMap((resp) => {
                            if (resp.status !== StatusApexResponse.Success) {
                                this.loggerService.error(
                                    'Errore chiamata API: ' + this.getApexApiUrl(ApexApi.MonitorQuote),
                                    null,
                                    JSON.stringify(resp)
                                );
                                return of(null);
                            } else return of(resp);
                        })
                    );
            })
        );
    }

    getQuoteDetail(quoteId: string): Observable<QuoteDetail> {
        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((c) => {
                const obfuscate = c.ObfuscateDetailDataInBackOffice;
                return this.api
                    .getAsync<QuoteDetailResponse>(this.getApexApiUrl(ApexApi.QuoteDetail), {
                        ...NO_LOADING,
                        params: { quoteId, obfuscate },
                    })
                    .pipe(mergeMap((res: QuoteDetailResponse) => this.manageAptResponse(res)));
            })
        );
    }

    getQuoteLineItemDetail(quoteLineItemId: string): Observable<QuoteLineItemDetail> {
        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((c) => {
                const obfuscate = c.ObfuscateDetailDataInBackOffice;
                return this.api
                    .getAsync<QuoteLineItemDetailResponse>(this.getApexApiUrl(ApexApi.QuoteLineItemDetail), {
                        ...NO_LOADING,
                        params: { qliId: quoteLineItemId, obfuscate },
                    })
                    .pipe(
                        mergeMap((res: QuoteLineItemDetailResponse) => {
                            return this.manageAptResponse(res);
                        }),
                        mergeMap((res: any) => {
                            this.remapTechnicalVariationResult(res);
                            return of(res);
                        })
                    );
            })
        );
    }

    monitoraggioOrderList(
        itemsSkip: number,
        itemsTake: number,
        fs: MonitoraggioFilters,
        inadmissibilityFilter: InadmissibilityFilter,
        commodityType: FilterCommodityType,
        commonConds: BoCommonFilters,
        profile: D365ProfileCode,
        userChannel: D365ChannelCode
    ): Observable<MonitoraggioOrderResponse> {
        const req = new MonitoraggioOrderRequest();
        req.Filtri.skip = itemsSkip;
        req.Filtri.take = itemsTake;
        req.Filtri.type = inadmissibilityFilter.type;

        if (inadmissibilityFilter.subType) {
            req.Filtri.subType = inadmissibilityFilter.subType;
        }

        req.Filtri.commodityType = commodityType;
        req.Obfuscate = true;
        req.Profile = profile;
        req.UserChannel = userChannel;

        if (fs?.conds?.searchByField) {
            req.Filtri.generic = {
                key: fs.conds.searchByField.field,
                value: fs.conds.searchByField.value,
            };
        }
        req.Filtri.status = [];
        if (fs?.conds?.statusOrder) {
            req.Filtri.status = fs.conds.statusOrder as AptOrderStatus[];
        }
        this.manageFilterDates(req, fs?.conds?.date);
        if (fs?.conds?.orderBy) {
            const sort = fs.conds.orderBy.split('|');
            req.Filtri.sortByField = sort[0];
            req.Filtri.sortByType = sort[1] as 'ASC' | 'DESC';
        }
        req.Filtri.salesProcess = fs?.operativeMode;
        this.manageCommonBoFilter(commonConds, req);

        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((c) => {
                req.Obfuscate = c.ShouldObfuscateDataInBackOffice;
                return this.api
                    .postAsync<MonitoraggioOrderResponse>(
                        this.getApexApiUrl(ApexApi.MonitorOrder),
                        req,
                        undefined,
                        NO_LOADING
                    )
                    .pipe(
                        mergeMap((resp) => {
                            if (resp.status !== StatusApexResponse.Success) {
                                this.loggerService.error(
                                    'Errore chiamata API: ' + this.getApexApiUrl(ApexApi.MonitorOrder),
                                    null,
                                    JSON.stringify(resp)
                                );
                                return of(null);
                            } else return of(resp);
                        })
                    );
            })
        );
    }

    getPaymentQuoteDetail(quoteId: string): Observable<PaymentDetail> {
        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((c) => {
                const obfuscate = c.ObfuscateDetailDataInBackOffice;
                return this.api
                    .getAsync<PaymentDetailResponse>(this.getApexApiUrl(ApexApi.PaymentDetail), {
                        ...NO_LOADING,
                        params: { quoteId, obfuscate },
                    })
                    .pipe(
                        mergeMap((res: PaymentDetailResponse) => {
                            return this.manageAptResponse(res);
                        })
                    );
            })
        );
    }

    getPaymentOrderDetail(orderId: string): Observable<PaymentDetail> {
        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((c) => {
                const obfuscate = c.ObfuscateDetailDataInBackOffice;
                return this.api
                    .getAsync<PaymentDetailResponse>(this.getApexApiUrl(ApexApi.PaymentOrder), {
                        ...NO_LOADING,
                        params: { orderId, obfuscate },
                    })
                    .pipe(
                        mergeMap((res: PaymentDetailResponse) => {
                            return this.manageAptResponse(res);
                        })
                    );
            })
        );
    }

    /**
     * Restituisce i dati relativi alla vendita abbinata
     * @param quoteId
     */

    async getCombinedSales(quoteId: string): Promise<CombinedSalesItemsResponse> {
        try {
            const req = {
                quoteId: quoteId,
            };
            const tRes = await this.api
                .getAsync<CombinedSalesItemsResponse>(this.getApexApiUrl(ApexApi.GetCombinedSales), req)
                .toPromise();
            let res = new CombinedSalesItemsResponse();
            if (tRes) {
                res = { ...tRes };
            }
            return res;
        } catch (error) {
            return null;
        }
    }

    /**
     * Restituisce la lista della azioni relative ad una quote
     * @param quoteId
     * @param profilo profilo utente
     */
    getActionsByQuoteId(
        quoteId: string,
        profilo: D365ProfileCode,
        channels: Array<D365ChannelCode>,
        salesProcess: AptSalesProcess
    ): Observable<AzioneBo[]> {
        const req = new ManageQuoteRequest();
        req.Filtri.quoteId = quoteId;
        req.Filtri.profilo = profilo;
        req.Filtri.salesProcess = salesProcess;
        req.Filtri.channel = channels;

        return this.api.postAsync<ManageQuoteResponse>(this.getApexApiUrl(ApexApi.ManageQuoteList), req).pipe(
            tap((res) => {
                if (res?.status !== StatusApexResponse.Success) {
                    throw new Error(res.errorMessage || this.GENERIC_ERROR);
                }
            }),
            map(({ Quote }) => (Quote || [])[0]?.Azioni || [])
        );
    }

    getActions(
        canale: D365ChannelCode,
        profilo: D365ProfileCode,
        salesProcess: AptSalesProcess,
        orderId: string = null,
        quoteId: string = null
    ): Observable<ActionFilterResponse> {
        const req = {
            profile: profilo,
            channel: canale,
            salesProcess,
            quoteId: quoteId,
            orderId: orderId,
        };
        return this.api
            .postAsync<ActionFilterResponse>(this.getApexApiUrl(ApexApi.ActionsFilters), req, undefined, NO_LOADING)
            .pipe(
                mergeMap((res: ActionFilterResponse) => {
                    if (res.status === StatusApexResponse.Failed) {
                        this.loggerService.error(
                            'Error while retrieving data from Apttus.',
                            res.errorMessage,
                            null,
                            true
                        );
                        throw new Error(res.errorMessage);
                    } else {
                        return of(res);
                    }
                })
            );
    }

    async changeCartOwner(
        newAgentDomainName: string,
        cartIdList: string[],
        DAGCode: string
    ): Promise<ChangeCartOwnerResponse> {
        try {
            const req = {
                newAgentDomainName: newAgentDomainName,
                cartIdList: cartIdList,
                DAGCode: DAGCode,
            };
            const tRes = await this.api
                .postAsync<ChangeCartOwnerResponse>(
                    this.getApexApiUrl(ApexApi.ChangeCartOwner),
                    req,
                    undefined,
                    NO_LOADING
                )
                .toPromise();
            let res = new ChangeCartOwnerResponse();
            if (tRes) {
                res = { ...tRes };
            }
            return res;
        } catch (error) {
            return null;
        }
    }

    /**
     * Esegue l'aggiornamento del codice Ateco sui pav e sull'account in caso di inserimento posticipato da back-office
     * @param lineItemId : Id del lineitem
     * @param atecoCode : Codice Ateco
     * @param commoditySector : Attività Merceologica
     * @param commercialBranch : Settore Commerciale
     */
    async updateAtecoData(
        lineItemId: string,
        atecoCode: string,
        commoditySector: string,
        commercialBranch: string
    ): Promise<ApttusDoOperationResponse> {
        const req = {
            LineItemId: lineItemId,
            AtecoCode: atecoCode,
            CommoditySector: commoditySector,
            CommercialBranch: commercialBranch,
        };

        return this.api
            .postAsync<ApttusDoOperationResponse>(this.getApexApiUrl(ApexApi.UpdateAtecoCode), req)
            .toPromise()
            .catch(() => {
                return null;
            });
    }

    private manageCommonBoFilter(commonConds: BoCommonFilters, req: any, channel: string = null) {
        if (commonConds?.agencyBranch) {
            req.Filtri.agencyBranch = commonConds.agencyBranch.filter((c) => !!c.name).map((ab) => ab.name);
        }

        if (commonConds?.virtualAgencies) {
            req.Filtri.virtualAgencies = commonConds.virtualAgencies.filter((c) => !!c.code).map((ab) => ab.code);
        }

        req.Filtri.channel = req.Filtri.channel ?? [];
        if (commonConds?.channels) {
            req.Filtri.channel = commonConds.channels.filter((c) => !!c.code).map((ab) => ab.code);
        }
        if (channel) req.Filtri.channel.push(channel);

        if (commonConds?.agent) {
            req.Filtri.agent = commonConds.agent
                .filter((c) => !!c.code)
                .map(
                    (ab) =>
                        ({
                            coCode: ab.secondaryCode,
                            salesAgent: ab.code,
                        } as BoAgentFilter)
                );
        }
    }

    private manageFilterDates(
        request: MonitoraggioOrderRequest | MonitoraggioQuoteRequest | ManageQuoteRequest,
        dateFilters: BoFilterByDate
    ) {
        if (!!dateFilters?.type && !!dateFilters?.dateStart && !!dateFilters?.dateEnd) {
            request.Filtri.dateFilter = dateFilters?.type;
            request.Filtri.dateFrom = moment(dateFilters?.dateStart).format(this.dateFromFormat);
            request.Filtri.dateTo = moment(dateFilters?.dateEnd).format(this.dateToFormat);
        }
    }

    getOrderDetail(orderId: string): Observable<OrderDetail> {
        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((c) => {
                const obfuscate = c.ObfuscateDetailDataInBackOffice;
                return this.api
                    .getAsync<OrderDetailResponse>(this.getApexApiUrl(ApexApi.OrderDetail), {
                        ...NO_LOADING,
                        params: { orderId, obfuscate },
                    })
                    .pipe(mergeMap((res: OrderDetailResponse) => this.manageAptResponse(res)));
            })
        );
    }

    getOrderActions(orderId: string): Observable<OrderLineItemAction[]> {
        return this.api
            .getAsync<OrderActionsResponse>(this.getApexApiUrl(ApexApi.OrderActions), {
                ...NO_LOADING,
                params: { orderId },
            })
            .pipe(
                mergeMap((res: OrderActionsResponse) => this.manageAptResponse<OrderActions>(res)),
                map((res) => (res?.actions || []).map((action) => ({ isInadmissibilityAction: true, ...action }))),
                catchError(() => of([]))
            );
    }

    getOrderLineItemDetail(orderLineItemId: string): Observable<OrderLineItemDetailExtended> {
        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((c) => {
                const obfuscate = c.ObfuscateDetailDataInBackOffice;
                return this.api
                    .getAsync<OrderLineItemDetailResponse>(this.getApexApiUrl(ApexApi.OrderLineItemDetail), {
                        ...NO_LOADING,
                        params: { oliId: orderLineItemId, obfuscate },
                    })

                    .pipe(
                        mergeMap((res: OrderLineItemDetailResponse) => {
                            return this.manageAptResponse(res);
                        }),
                        mergeMap((res: any) => {
                            this.remapTechnicalVariationResult(res);
                            return of(res);
                        })
                    );
            })
        );
    }

    /**
     * Consente di effettuare la risottomissione di un ordine nel caso di inammissibilità
     * @param orderId Id dell'ordine per il quale si desidera eseguire la risottomissione
     * @returns Ritorna un observable che emetterà un valore booleno che indica se l'operazione è andata a buon fine o meno.
     */
    resubmitOrder(orderId: string): Observable<boolean> {
        return this.api
            .postAsync<ResubmitOrderResponse>(this.getApexApiUrl(ApexApi.ResubmitOrder), { orderId: orderId })
            .pipe(
                mergeMap((res: ResubmitOrderResponse) => this.manageAptResponse(res)),
                catchError(() => of(false)),
                map(() => true)
            );
    }

    getQuoteOrderHistory(object: HistoryObjectType, id: string, returnUser: boolean): Observable<HistoryItem[]> {
        const body: GetQuoteOrderHistoryRequest = { object: object, Id: id, returnUser: returnUser };
        return this.api
            .postAsync<GetQuoteOrderHistoryResponse>(this.getApexApiUrl(ApexApi.GetQuoteOrderHistory), body)
            .pipe(
                map((res: GetQuoteOrderHistoryResponse) => {
                    if (res.status === StatusResponse.Failed) {
                        this.loggerService.error(
                            'Error while retrieving data from Apttus.',
                            res.errorManagement?.errorDescription,
                            null,
                            true
                        );
                        return null;
                    }

                    return res.response?.HistoryListRes;
                })
            );
    }

    getAssetReportAssetView(assetReportSearchRequest: GetAssetReportViewsRequest): Observable<AssetReportAssetItem[]> {
        const request: GetAssetReportViewsRequest = assetReportSearchRequest;
        const opts = {
            headers: {
                ...{ ['no-loading']: 'true' },
            },
        };

        return this.api
            .postAsync<AssetReportAssetViewResponse>(
                this.getApexApiUrl(ApexApi.GetAssetReportAssetView),
                request,
                0,
                opts
            )
            .pipe(
                map((res: any) => {
                    if (res.status === StatusResponse.Failed) {
                        this.loggerService.error(
                            'Error while retrieving data from Apttus.',
                            res.errorManagement?.errorDescription,
                            null,
                            true
                        );
                        return null;
                    }

                    return res?.assetList;
                })
            );
    }

    getAssetReportOliView(assetReportSearchRequest: GetAssetReportViewsRequest): Observable<AssetReportOliView[]> {
        const request: GetAssetReportViewsRequest = assetReportSearchRequest;
        const opts = {
            headers: {
                ...{ ['no-loading']: 'true' },
            },
        };

        return this.api
            .postAsync<AssetReportOliViewResponse>(this.getApexApiUrl(ApexApi.GetAssetReportOliView), request, 0, opts)
            .pipe(
                map((res: any) => {
                    if (res.status === StatusResponse.Failed) {
                        this.loggerService.error(
                            'Error while retrieving data from Apttus.',
                            res.errorManagement?.errorDescription,
                            null,
                            true
                        );
                        return null;
                    }

                    return res?.oliList;
                })
            );
    }

    getAssetReportOtiView(assetReportSearchRequest: GetAssetReportViewsRequest): Observable<AssetReportOtiView[]> {
        const request: GetAssetReportViewsRequest = assetReportSearchRequest;
        const opts = {
            headers: {
                ...{ ['no-loading']: 'true' },
            },
        };

        return this.api
            .postAsync<AssetReportAssetViewResponse>(
                this.getApexApiUrl(ApexApi.GetAssetReportOtiView),
                request,
                0,
                opts
            )
            .pipe(
                map((res: any) => {
                    if (res.status === StatusResponse.Failed) {
                        this.loggerService.error(
                            'Error while retrieving data from Apttus.',
                            res.errorManagement?.errorDescription,
                            null,
                            true
                        );
                        return null;
                    }

                    return res?.otiList;
                })
            );
    }

    private remapTechnicalVariationResult(res: { variations: CheckAttributeItem[] }) {
        if (res?.variations?.length) {
            res.variations = res.variations.map((c: CheckAttributeItem) => {
                return {
                    ...c,
                    newValue:
                        c.newValue == 'Y'
                            ? this.translateSrv.instant('COMMON.YES')
                            : c.newValue == 'N'
                            ? this.translateSrv.instant('COMMON.NO')
                            : c.newValue,
                    oldValue:
                        c.oldValue == 'Y'
                            ? this.translateSrv.instant('COMMON.YES')
                            : c.oldValue == 'N'
                            ? this.translateSrv.instant('COMMON.NO')
                            : c.oldValue,
                } as CheckAttributeItem;
            });
        }
    }

    private manageAptResponse<T>(res: BaseApexApiResponse<T>): Observable<T> {
        if (res?.status !== StatusApexResponse.Success) {
            this.loggerService.error(
                'Error while retrieving data from Apttus.',
                res?.errorMessage || this.GENERIC_ERROR,
                null,
                true
            );
            throw new Error(res?.errorMessage);
        } else {
            return of(res.result);
        }
    }

    signDoiLearning(quoteId: string): Observable<BaseApexApiResponse<null>> {
        if (!this.configSrv.get<boolean>('learningStubs')) {
            return null;
        }

        return this.api
            .postAsync<BaseApexApiResponse<null>>(this.getApexApiUrl(ApexApi.LearningDoiSign), {
                codiceCarrello: quoteId,
                stato: 'CONFERMATO',
            })
            .pipe(
                mergeMap((res: BaseApexApiResponse<null>) => {
                    return this.manageAptResponse(res);
                })
            );
    }

    jumpToOrderLearning(quoteId: string): Observable<BaseApexApiResponse<null>> {
        if (!this.configSrv.get<boolean>('learningStubs')) {
            return null;
        }

        return this.api
            .postAsync<BaseApexApiResponse<null>>(this.getApexApiUrl(ApexApi.LearningJumpToOrder), {
                quoteId: quoteId,
            })
            .pipe(
                mergeMap((res: BaseApexApiResponse<null>) => {
                    return this.manageAptResponse(res);
                })
            );
    }

    changeEffectiveDate(
        isInadmissible: boolean,
        effectiveDate: Date,
        orderLineItemId: string,
        oliType?: string
    ): Observable<boolean> {
        const obs$ = isInadmissible
            ? this.reworkService({ orderLineItemId, effectiveDate }).pipe(
                  tap((response) => {
                      if (response?.Status !== ReworkStatusResponse.Success) {
                          throw new Error(response?.ErrorMessage || this.GENERIC_ERROR);
                      }
                  }),
                  map(() => true)
              )
            : this.api
                  .postAsync<BaseApiResponse<{ message: string }>>(
                      this.getApiMngApiUrl(ApiMngApi.ChangeEffectiveDate),
                      <ChangeEffectiveDateRequest>{
                          dataDecorrenze: moment(effectiveDate).format('DD/MM/YYYY'),
                          IdOLI: orderLineItemId,
                          tipo: oliType,
                      }
                  )
                  .pipe(
                      map((response) =>
                          response instanceof HttpErrorResponse ? (<HttpErrorResponse>response)?.error : response
                      ),
                      tap((response) => {
                          if (response?.status !== StatusResponse.Success) {
                              throw new Error(response?.errorManagement?.errorDescription || this.GENERIC_ERROR);
                          }
                      }),
                      map(() => true)
                  );
        return obs$.pipe(
            catchError((error: Error | HttpErrorResponse) => {
                this.loggerService.error('ChangeEffectiveDate Error', error?.message, error, false, {
                    isInadmissible: !!isInadmissible,
                });
                return of(false);
            })
        );
    }

    /**
     * @description Recupera i dati della ricerca cliente 'Customer Engagement' in Customer Service Workspace
     * @param key codice fiscale, codice conto cliente, codice cliente o email
     */
    getCustomerEngagementData(searchParams: { key: string; value: string }): Observable<CustomerEngagementResponse> {
        return this.api
            .getAsync<CustomerEngagementResponse>(this.getApiMngApiUrl(ApiMngApi.CustomerEngagement), {
                [searchParams.key]: searchParams.value,
            })
            .pipe(
                tap((response) => {
                    if (response instanceof HttpErrorResponse) {
                        throw new Error('Impossibile eseguire la chiamata al Server');
                    }
                }),
                catchError((error) => {
                    this.loggerService.error('Customer Engagement', error?.message, error, false);
                    return throwError(error);
                })
            );
    }
}

export class ApimError extends Error {
    public code: string;
    constructor(description: string, code: string) {
        super(description);
        this.code = code;
    }
}
