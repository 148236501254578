<form [formGroup]="troubleshootingForm" *ngIf="troubleshootingForm">
    <div class="egl-fieldset__radiobutton-column-title-container">
        <h4 class="egl-fieldset__radiobutton-column-title--y">{{ 'COMMON.YES' | translate }}</h4>
        <h4 class="egl-fieldset__radiobutton-column-title--n">{{ 'COMMON.NO' | translate }}</h4>
    </div>
    <ng-container
        *ngFor="
            let item of template
                | troubleshooter: questionResult:(troubleshootingForm?.valueChanges | async):troubleshootingParams
        "
    >
        <fieldset class="egl-fieldset__radiobutton-input-container">
            <p class="egl-fieldset__paragraph">
                {{
                    item.label ||
                        ('TROUBLESHOOTING_QUESTIONS.' + troubleshootingTemplate + '.' + item.labelId + '.LABEL'
                            | translate)
                }}
                <span *ngIf="item.subLabel || item.subLabelId" class="egl-fieldset__span">
                    {{
                        item.subLabel ||
                            ('TROUBLESHOOTING_QUESTIONS.' +
                                troubleshootingTemplate +
                                '.' +
                                item.subLabelId +
                                '.SUB_LABEL' | translate)
                    }}
                </span>
            </p>
            <div class="egl-fieldset__radiobutton-input">
                <input
                    type="radio"
                    [value]="!item.isValueInverted ? item.value : 0"
                    formControlName="{{ item.value }}"
                    class="egl-fieldset__radiobutton-check"
                />
            </div>
            <div class="egl-fieldset__radiobutton-input">
                <input
                    type="radio"
                    [value]="!item.isValueInverted ? 0 : item.value"
                    formControlName="{{ item.value }}"
                    class="egl-fieldset__radiobutton-check"
                />
            </div>
        </fieldset>
    </ng-container>
</form>
