import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { pick } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { CheckBalanceResponse } from '../../modules/common/order-entry/models/check-balance.response';
import { CheckIbanResponse, PersonaFisicaGiuridica } from '../../modules/common/order-entry/models/check-iban-response';
import { TerminationOrderService } from '../../modules/switch-in/order-entry/steps/02-dati-prodotto/ko-credit-check/termination-order.service';
import { setScoreCardStatus } from '../../store/actions/order-entry.actions';
import { MacroFlowType } from '../../store/models/flow-type';
import { OrderEntryState } from '../../store/models/order-entry-state';
import { Product } from '../../store/models/order-entry-state_v2';
import { EglState } from '../../store/reducers';
import {
    v2isScoreCardRequired,
    v2OrderEntryState,
    v2SelectDistinctCommodities,
    v2SelectMacroFlowType,
    v2SelectVisibleProducts,
} from '../../store/selectors/order-entry-v2.selectors';
import { productsFieldRetrieverGenerator } from '../../store/selectors/selector-utility.functions';
import { selectCartSegment, selectContact, selectCurrentProfile } from '../../store/selectors/user.selectors';
import { AptPaymentInstrument } from '../enums/apttus/apt-payment-instrument';
import { ChannelCodeInverse } from '../enums/d365/d365-channel-code';
import { D365CustomerSegment } from '../enums/d365/d365-customer-segment';
import { getTransactionId } from '../functions/misc.functions';
import { getCompleteCivic } from '../functions/string-format.functions';
import { BaseApiResponse, StatusResponse } from '../interfaces/base-api-response';
import { LegalFormType, RetrieveCreditCheckRequest, RetrieveInsolutoRequest } from '../models/app/credit-check.request';
import { EsitiData, InsolutoResponseSiebel } from '../models/app/recupera-dati-salesup.response';
import { ServiceError } from '../models/app/service-error';
import { Contact } from '../models/user/contact';
import { TelemetryMetricService } from '../services/app/telemetry-metric.service';
import { ApiService } from '../services/shared/api.service';
import { LoadingService } from '../services/shared/loading.service';
import { LoggerService } from '../services/shared/logger.service';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';
import { ApiMngApi, BaseProvider } from './base-provider';
import { CommonProvider } from './common-provider';

@Injectable({ providedIn: 'root' })
export class CreditCheckProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        protected configSrv: PrivateConfigurationService,
        private store: Store<EglState>,
        private logger: LoggerService,
        private telemetrySrv: TelemetryMetricService,
        protected terminationOrderService: TerminationOrderService,
        translate: TranslateService,
        private commonProvider: CommonProvider,
    ) {
        super(configSrv, translate);
    }

    /**
     * @description: credit check by CF or PIVA for specific users category
     * @param req
     */
    creditCheckFromDDL(
        req: Omit<RetrieveCreditCheckRequest, 'checkTypes'> & {
            checkTypes: ScoreCardCheckTypes[];
        },
    ): Observable<EsitiData & { esito: boolean }> {
        const CHECK_TYPES_MAP: {
            [key in ScoreCardCheckTypes]: (keyof EsitiData)[];
        } = {
            [ScoreCardCheckTypes.Credit]: ['creditCheck', 'insolutoNDS'],
            [ScoreCardCheckTypes.ScoreCard]: ['scorecard', 'insolutoNDS'],
            [ScoreCardCheckTypes.TaxCode]: ['esitoCF', 'insolutoNDS'],
        };

        return this.commonProvider.getBusinessTransactionId().pipe(
            map((businessTrxId) => ({
                headers: {
                    businessTrxId,
                },
            })),
            mergeMap((options) =>
                this.api.postAsync<BaseApiResponse<EsitiData>>(
                    this.getApiMngApiUrl(ApiMngApi.RetrieveDataCreditCheck),
                    {
                        ...(req || ({} as RetrieveCreditCheckRequest)),
                        checkTypes: (req?.checkTypes || []).join(','),
                    },
                    0,
                    options,
                ),
            ),
            tap((res) => {
                if (
                    res?.status !== StatusResponse.Success &&
                    ['DDL_009', 'DDL_010'].includes(res?.errorManagement?.errorCode)
                ) {
                    throw new Error(res?.errorManagement?.errorCode);
                }
                if (res?.status !== StatusResponse.Success || !res?.response) {
                    throw new Error(res?.errorManagement?.errorDescription || 'Credit check error.');
                }
            }),
            map((res) => res?.response),
            map((res) => {
                const responseFields: (keyof EsitiData)[] = [].concat(
                    ...req.checkTypes.map((checkType) => CHECK_TYPES_MAP[checkType]),
                );
                return pick(res, responseFields);
            }),
            map((res) => ({
                ...res,
                esito: Object.values(res)
                    .map(({ esito }) => esito || esito === null)
                    .every(Boolean),
            })),
        );
    }

    /**
     * Per clienti Siebel
     * @param req
     */
    creditCheckInsoluto(req: RetrieveInsolutoRequest): Observable<BaseApiResponse<InsolutoResponseSiebel>> {
        return this.commonProvider.getBusinessTransactionId().pipe(
            map((businessTrxId) => ({
                headers: {
                    businessTrxId,
                },
            })),
            mergeMap((options) =>
                this.api.postAsync<BaseApiResponse<EsitiData>>(
                    this.getApiMngApiUrl(ApiMngApi.RetrieveInsoluto),
                    req,
                    0,
                    options,
                ),
            ),
            catchError(() => of(null)),
        );
    }

    /**
     * @description Esegue la chiamata all'API di APIManagement per la verifica della validità dell'IBAN e dell'Insoluto NDS associato
     * @param ibanVal L'iban da verificare
     * @param subjectType tipologia soggetto se PF o PG
     * @returns Promise<BaseApiResponse<CheckIbanResponse>>
     */
    ibanCheck(
        IBAN: string,
        subjectType: PersonaFisicaGiuridica,
        vatCodeOrTaxCode: string,
        provinceCode: string,
    ): Observable<BaseApiResponse<CheckIbanResponse>> {
        return this.commonProvider.getBusinessTransactionId().pipe(
            map((businessTrxId) => ({
                headers: {
                    businessTrxId,
                },
            })),
            mergeMap((options) =>
                this.api.postAsync<BaseApiResponse<CheckIbanResponse>>(
                    this.getApiMngApiUrl(ApiMngApi.CheckIban),
                    {
                        IBAN,
                        subjectType,
                        vatRegistrationNo: vatCodeOrTaxCode,
                        provinceCode,
                    },
                    0,
                    options,
                ),
            ),
        );
    }

    scoreCard(
        checkTypes: ScoreCardCheckTypes[] = [ScoreCardCheckTypes.ScoreCard],
    ): Observable<EsitiData & { esito: boolean }> {
        return this.store.select(v2isScoreCardRequired).pipe(
            take(1),
            // Gestisco la casistica in cui non devo fare la ScoreCard come un errore per saltare tutto il processo
            tap((isScoreCardRequired) => {
                if (!isScoreCardRequired) {
                    throw null;
                }
            }),
            mergeMap(() =>
                combineLatest([
                    this.store.select(v2SelectMacroFlowType),
                    // Richiamata nel Dragon Router ed eseguita per l'item corrente nelle sotto rotte
                    this.store.select(v2SelectDistinctCommodities()),
                    this.store.select(selectCurrentProfile),
                    this.store.select(selectContact),
                    this.store.select(selectCartSegment),
                    this.store.select(v2OrderEntryState),
                    this.store.select(v2SelectVisibleProducts('ALL')),
                ]),
            ),
            take(1),
            map(
                ([
                    macroFlowType,
                    commodities,
                    currentProfile,
                    contact,
                    cartSegment,
                    { anagraficaMb, creditCheckStatus },
                    products,
                ]: [MacroFlowType, Product[], any, Contact, D365CustomerSegment, OrderEntryState, Product[]]): Omit<
                    RetrieveCreditCheckRequest,
                    'checkTypes'
                > & {
                    checkTypes: ScoreCardCheckTypes[];
                } => {
                    const getProduct = productsFieldRetrieverGenerator(commodities);
                    const phoneNumbers = [
                        `${contact?.prefixMobilephone || ''}${contact?.mobilephone || ''}`.trim(),
                        `${contact?.prefixTelephone1 || ''}${contact?.telephone1 || ''}`.trim(),
                        `${contact?.prefixTelephone2 || ''}${contact?.telephone2 || ''}`.trim(),
                    ].filter(Boolean);

                    const isMicrobusiness = cartSegment === D365CustomerSegment.Microbusiness;
                    const PAYMENT_MODE_MAP: {
                        [key in AptPaymentInstrument | string]?: string;
                    } = {
                        [AptPaymentInstrument.Bollettino]: 'Bollettino',
                        [AptPaymentInstrument.AddebitoCC]: 'Domiciliazione',
                        ['DEFAULT']: 'ALTRO',
                    };
                    const OPERATING_MODE_MAP: {
                        [key in MacroFlowType | string]: string;
                    } = {
                        [MacroFlowType.SwitchIn]: 'SWITCH IN',
                        [MacroFlowType.Attivazione]: 'ATTIVAZIONE',
                        [MacroFlowType.Voltura]: 'VOLTURA',
                        [MacroFlowType.VolturaSwitchIn]: 'VOLTURA CON SWITCH',
                        [MacroFlowType.CambioProdotto]: 'CAMBIO PRODOTTO',
                        ['DEFAULT']: 'ALTRO',
                    };
                    return {
                        checkTypes,
                        ...(!isMicrobusiness && { fiscalCode: contact?.egl_taxcode }),
                        ...(isMicrobusiness && { vatNumber: contact?.egl_vatcode }),
                        customerId: contact?.egl_customercode,
                        iban: getProduct((product) => product?.paymentInfo?.paymentTool?.iban),
                        phone: phoneNumbers[0],
                        email: contact?.emailaddress1,
                        cervedVerification: {
                            subjectType: isMicrobusiness ? 'PG' : 'PF',
                            customerType:
                                (anagraficaMb?.legalForm as LegalFormType) || LegalFormType.PersonaFisicaPrivato,
                            scoreCardId: isMicrobusiness ? 'ENIPG2' : 'ENIPF2',
                            taxCode: contact.egl_taxcode,
                            surname: contact.lastname,
                            name: contact.firstname,
                            Iban: getProduct((product) => product?.paymentInfo?.paymentTool?.iban),
                            customerTransactionId: getTransactionId(),
                            paymentScore: creditCheckStatus?.ccDetails?.paymentScore,
                            isCustomer: {
                                isCustomerFlag: contact?.isCustomer,
                                internalId: contact?.isCustomer ? contact.accountid : null,
                            },
                            isFormerCustomer: {
                                isFormerCustomerFlag: contact?.isFormerCustomer,
                                ceaseReasonCode: creditCheckStatus?.ccDetails?.ceaseReasonCode,
                            },
                            acquisitionAgency: {
                                codeAgency: currentProfile?.selectedVirtualAg?.DefaultAgencyBranch?.Code,
                                descAgency: currentProfile?.selectedVirtualAg?.DefaultAgencyBranch?.Name,
                            },
                            aquisitionChannel: currentProfile?.channelCode
                                ? ChannelCodeInverse[currentProfile?.channelCode]
                                : null,
                            operatingMode: OPERATING_MODE_MAP[macroFlowType || 'DEFAULT'],
                            checkType: 'SCORECARD',
                            customerContactInfo: {
                                email: contact?.emailaddress1,
                                phoneNumbers,
                                shippingAddress: commodities?.length
                                    ? {
                                          postCode: getProduct((product) => product?.communicationAddress.cap),
                                          provinceCode: getProduct(
                                              (product) => product?.communicationAddress.shortProvince,
                                          ),
                                          dug: getProduct((product) => product?.communicationAddress.toponym),
                                          street: getProduct((product) => product?.communicationAddress.street),
                                          number: getProduct((product) =>
                                              getCompleteCivic(product?.communicationAddress),
                                          ),
                                          municipality: {
                                              name: getProduct((product) => product?.communicationAddress.municipality),
                                          },
                                      }
                                    : {
                                          postCode: products[0]?.communicationAddress?.cap,
                                          provinceCode: products[0]?.communicationAddress?.shortProvince,
                                          dug: products[0]?.communicationAddress?.toponym,
                                          street: products[0]?.communicationAddress?.street,
                                          number: getCompleteCivic(products[0]?.communicationAddress),
                                          municipality: {
                                              name: products[0]?.communicationAddress?.municipality,
                                          },
                                      },
                            },
                            supplyPointsInfo: commodities.map((commodity) => ({
                                type: commodity.powerOrGas,
                                usageValue: commodity?.technicalDetails?.consumption || 0,
                                supplyAddress: {
                                    postCode: commodity.deliveryAddress.cap,
                                    provinceCode: commodity.deliveryAddress.shortProvince,
                                    dug: commodity.deliveryAddress.toponym,
                                    street: commodity.deliveryAddress.street,
                                    number: getCompleteCivic(commodity.deliveryAddress),
                                    municipality: {
                                        name: commodity?.deliveryAddress.municipality,
                                    },
                                },
                            })) as [],
                            paymentInfo: commodities?.length
                                ? {
                                      paymentMode: getProduct(
                                          (product) =>
                                              PAYMENT_MODE_MAP[product?.paymentInfo?.paymentInstrument || 'DEFAULT'],
                                      ),
                                  }
                                : {
                                      paymentMode:
                                          PAYMENT_MODE_MAP[products[0]?.paymentInfo?.paymentInstrument || 'DEFAULT'],
                                  },
                        },
                    };
                },
            ),
            mergeMap((data) => this.creditCheckFromDDL(data)),
            catchError((err) => {
                if (err === null) {
                    return of(null);
                }
                throw err;
            }),
        );
    }

    public orderEntryScoreCard(checkTypes?: ScoreCardCheckTypes[]): Observable<boolean> {
        return this.scoreCard(checkTypes).pipe(
            this.telemetrySrv.rxTelemetry('Performance: End Call Scorecard service'),
            mergeMap((esiti) =>
                this.store.select(selectContact).pipe(
                    take(1),
                    map((contact) => ({
                        esiti,
                        customerData: {
                            cfPiva: contact?.egl_vatcode || contact?.egl_taxcode,
                            firstname: contact?.firstname || null,
                            lastnameOrName: contact?.lastname || contact?.name,
                        },
                    })),
                ),
            ),
            mergeMap(({ esiti, customerData }) =>
                esiti ? this.terminationOrderService.manageCheckDdlResult(esiti, customerData) : of(null),
            ),
            tap((res) => {
                // Gestisco il OK logico, verifica creditizia superata
                if (res?.esito) {
                    this.logger.warn(
                        `Esito Scorecard positivo (esito = true). Faccio proseguire con descrizioneEsito = ${res?.scorecard?.dettaglio}`,
                    );

                    this.store.dispatch(
                        setScoreCardStatus({
                            s: res?.scorecard?.dettaglio,
                        }),
                    );
                } else {
                    // Gestisco la casistica in cui salto la creditCheck
                    this.logger.warn(
                        'Skip chiamata al nuovo servizio scorecard perchè non richiesta dal check dbCredito',
                    );
                }
            }),
            map(() => true),
            // Gestisco il KO logico, verifica creditizia non superata
            catchError((err: ServiceError) => {
                this.logger.warn(
                    `Chiamata a scorecard in errore - ${err?.message} - Gestisco come un caso di RETRY e faccio proseguire`,
                );
                // Gestisco gli errori tecnici
                if (!ServiceError.isServiceError(err)) {
                    this.store.dispatch(
                        setScoreCardStatus({
                            s: 'NON DISPONIBILE',
                        }),
                    );
                    return of(null);
                }
                this.logger.warn(
                    `Esito Scorecard negativo (false). ${
                        err?.code === 'CC_EXTRACOMMODITY'
                            ? 'Apro modale di suggerimento cambio PaymentInstrument'
                            : 'Navigo verso la pagina di KO Credit Check'
                    } `,
                );
                throw err;
            }),
            LoadingService.loaderOperator('Verifica creditizia'),
        );
    }

    checkBalance(cartAmount: number, accountNumber: string): Observable<BaseApiResponse<CheckBalanceResponse>> {
        return this.commonProvider.getBusinessTransactionId().pipe(
            mergeMap((businessTrxId) =>
                this.api.postAsync<BaseApiResponse<CheckBalanceResponse>>(
                    this.getApiMngApiUrl(ApiMngApi.CheckBalance),
                    {
                        channel: 'SUP',
                        codiceCliente: accountNumber,
                        totaleRataCarrello: cartAmount,
                    },
                    0,
                    {
                        headers: {
                            businessTrxId,
                        },
                    },
                ),
            ),
        );
    }
}

export enum ScoreCardCheckTypes {
    TaxCode = 'CF',
    Credit = 'CREDIT',
    ScoreCard = 'SCORECARD',
}
