import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    setCeaseReasonCode,
    setCreditCheckStatus,
    setIbanInsolutoNDS,
    setPaymentScore,
    setTaxVatStatus,
} from '../../../store/actions/order-entry.actions';
import { EglState } from '../../../store/reducers';
import { CreditCheckStatus, TaxVatDetails } from '../../../store/models/order-entry-state';
import { EsitiData, TaxVatResult } from '../../models/app/recupera-dati-salesup.response';
import { D365CreditCheckOutcome } from '../../models/user/customer';
import * as moment from 'moment';
import { LoggerService } from './logger.service';
import { AptPaymentInstrument } from '../../enums/apttus/apt-payment-instrument';
import {
    selectCreditCheckStatus,
    selectFlowType,
    selectTipoPagamento,
} from '../../../store/selectors/order-entry.selectors';
import { from, Observable, of } from 'rxjs';
import { RoutesPaths } from '../../config/routes-paths';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FlowType, MacroFlowType } from '../../../store/models/flow-type';
import { flowTypeUtil, hasOnlyExtraCommodities } from '../../functions/verifications.functions';
import { SelectProductTypes } from '../../../store/models/select-product-types';
import { EglProductCreditCheckRuleService } from '../apttus/tables/product/egl-product-credit-check-rule.service';
import {
    v2SelectExtracommoditiesByFilter,
    v2SelectVisibleProducts,
} from '../../../store/selectors/order-entry-v2.selectors';
import { FeatureToggleService } from './feature-toggle.service';
import { AptPaymentType } from '../../enums/apttus/apt-payment-type';
import { CartService } from '@congacommerce/ecommerce';
import { CreditCheckProvider } from '../../providers/credit-check.provider';
import { BaseApiResponse, StatusDDLResponse, StatusResponse } from '../../interfaces/base-api-response';
import { ServiceError } from '../../models/app/service-error';
import {
    CheckBalanceResponse,
    CheckBalanceResult,
} from '../../../modules/common/order-entry/models/check-balance.response';
import { mapOrEmpty } from '../../functions/observable-operators';

@Injectable({
    providedIn: 'root',
})
export class CreditCheckService {
    private paymentType: AptPaymentInstrument;

    constructor(
        private store: Store<EglState>,
        private logger: LoggerService,
        private router: Router,
        private creditCheckRuleSrv: EglProductCreditCheckRuleService,
        private featureToggleSrv: FeatureToggleService,
        private injector: Injector
    ) {
        this.mapStateToProps();
    }

    private mapStateToProps(): void {
        this.store.select(selectTipoPagamento).subscribe((p) => {
            this.paymentType = p.paymentType;
        });
    }

    /**
     * @description Imposta nello state applicativo i dati relativi al nuovo credit check recuperati dalla "recuperaDatiSalesUp" (API) e "getaccount" (D365)
     * @param ccData Gli esiti dei controlli
     * @param code Il codice fiscale o la PIva associato al credit check appena effettuato
     * @returns void
     */
    setCreditCheckData(ccData: EsitiData, code: string, checkDate?: moment.Moment): void {
        const {
            whitelist,
            blacklist,
            blacklist_2: blacklist2,
            blacklist_3: newBlacklist,
        } = ccData.creditCheck.dettaglio;
        const { callScorecard, esito: esitoCC, codiceErrore: errorCodeCC } = ccData.creditCheck;
        const { esito: esitoNds, descrizioneEsito: unsolvedNds, codiceErrore: errorCodeNds } = ccData.insolutoNDS;
        const {
            importo_medio_fatture: avgInvoiceAmount,
            residuo_nds: residualNds,
            insoluto_zuora_cf: unsolvedTaxCodeNds,
        } = ccData.insolutoNDS.dettaglio || {};

        const unsolvedTaxCodeNdsResult = unsolvedNds === 'NON DISPONIBILE' ? 'DA FARE' : unsolvedNds;

        const creditCheckStatus: CreditCheckStatus = {
            canProceed: esitoCC && esitoNds,
            errorCode: errorCodeCC || errorCodeNds,
            code,
            ccDetails: {
                blacklist,
                blacklist2,
                newBlacklist,
                whitelist,
                unsolvedNds,
                avgInvoiceAmount,
                residualNds,
                unsolvedTaxCodeNds, //Soglia numerica
                unsolvedTaxCodeNdsResult, //Esito insoluto NDS sul CF/P.IVA
            },
            callScorecard,
            checkDate: checkDate || moment(),
        };
        this.store.dispatch(setCreditCheckStatus({ s: creditCheckStatus }));
    }

    /**
     * @description Imposta nello state applicativo i dati relativi allo skipCreditCheck
     * @returns void
     */
    setSkippedCreditCheckData() {
        const creditCheckStatus: CreditCheckStatus = {
            isFakeResponse: true,
            canProceed: true,
            errorCode: '',
            code: '',
            ccDetails: {
                blacklist: '',
                blacklist2: '',
                newBlacklist: '',
                whitelist: 'OK',
                unsolvedNds: '',
                avgInvoiceAmount: '',
                residualNds: '',
                unsolvedTaxCodeNds: '',
                unsolvedTaxCodeNdsResult: '',
                checkBalanceExceeded: CheckBalanceResult.NotNeeded,
            },
            callScorecard: false,
            checkDate: null,
        };
        this.store.dispatch(setCreditCheckStatus({ s: creditCheckStatus }));
    }

    /**
     * @description Imposta nello state applicativo gli esiti dei controlli credit check relativi al codice fiscale e PIva
     * @param data Il risultato dei controlli SCIPAFI e SDI
     * @returns void
     */
    setTaxVatData(data: TaxVatResult): void {
        //Scipafi è alternativo a SDI. Se è presente Scipafi vuol dire che SDI non era disponibile.
        const taxVatData: TaxVatDetails = {
            scipafi: data?.source === 'SCIPAFI' ? data?.dettaglio : null,
            sdi: data?.source === 'SDI' ? data?.dettaglio : null,
        };
        this.store.dispatch(setTaxVatStatus({ s: taxVatData }));
    }

    /**
     * @description Esegue la mappatura dei dati credit check dalla response di D365 al formato "recuperaDatiSalesUp" e setta il risultato nello state
     * @param ccData I dati del credit check e insoluto NDS
     * @param code Il codice fiscale o partiva iva associato al controlo credit check
     * @returns void
     */
    mapD365CreditCheckData(ccData: D365CreditCheckOutcome, code: string): void {
        if (!ccData?.CreditCheck || !ccData?.UnpaidNds) {
            return;
        }
        const {
            CreditCheckResult,
            CreditCheckCallScoreCard,
            CreditCheckErrorCode,
            CreditCheckBlacklist1,
            CreditCheckBlacklist2,
            CreditCheckBlacklist3,
            CreditCheckWhitelist,
        } = ccData.CreditCheck;
        const {
            UnpaidNdsErrorcode,
            UnpaidNdsResultDescription,
            UnpaidNdsResult,
            UnpaidNdsUnpaidZuoraCf,
            UnpaidNdsUnpaidAverageZuoraInvoice,
            UnpaidNdsUnpaidZuoraNds,
        } = ccData.UnpaidNds;
        const esiti: EsitiData = {
            creditCheck: {
                esito: CreditCheckResult,
                callScorecard: CreditCheckCallScoreCard,
                codiceErrore: CreditCheckErrorCode,
                dettaglio: {
                    blacklist: CreditCheckBlacklist1,
                    blacklist_2: CreditCheckBlacklist2,
                    blacklist_3: CreditCheckBlacklist3,
                    whitelist: CreditCheckWhitelist,
                },
            },
            insolutoNDS: {
                esito: UnpaidNdsResult,
                codiceErrore: UnpaidNdsErrorcode,
                descrizioneEsito: UnpaidNdsResultDescription,
                dettaglio: {
                    insoluto_zuora_cf: UnpaidNdsUnpaidZuoraCf,
                    importo_medio_fatture: UnpaidNdsUnpaidAverageZuoraInvoice,
                    residuo_nds: UnpaidNdsUnpaidZuoraNds,
                },
            },
        };
        const checkDate = moment(
            ccData.CreditCheck.CreditCheckLastUpdateFromSiebel || ccData.UnpaidNds.UnpaidNdsLastUpdateFromSiebel,
            null,
            'it'
        );
        this.setCreditCheckData(esiti, code, checkDate);
    }

    /**
     * @description Imposta nello state il valore "paymentScore" (Se il valore è null lo imposto su ALTRO)
     * @param paymentScore Il valore del paymentScore
     * @returns void
     */
    setPaymentScore(paymentScore: string): void {
        this.logger.info(`Valore paymentScore recuperato: '${paymentScore}'`);
        if (!paymentScore) {
            this.logger.warn('Valore paymentScore nullo. Lo imposto su ALTRO');
            paymentScore = 'ALTRO';
        }
        this.store.dispatch(setPaymentScore({ p: paymentScore }));
    }
    /**
     * @description Imposta nello state il valore "cessazioneMorosita"
     * @param code Il valore della cessazione morosità
     * @returns void
     */
    setCeaseReasonCode(code: string): void {
        this.logger.info(`Valore cessazioneMorosita recuperato: '${code}'`);
        this.store.dispatch(setCeaseReasonCode({ c: code }));
    }

    /**
     * @description Controlla se il metodo di pagamento è "Bollettino" e nel caso imposta l'esito dell'insoluto NDS dell'iban a "NON NECESSARIO".
     * Altrimenti sbianca il campo
     * @returns void
     */
    prepopulateUnsolvedIbanNds(): void {
        const unsolvedIban = this.paymentType === AptPaymentInstrument.Bollettino ? 'NON NECESSARIO' : undefined; //Se sto pagando con bollettino, imposto l'esito insoluto NDS iban a NON NECESSARIO
        this.store.dispatch(setIbanInsolutoNDS({ i: unsolvedIban }));
    }

    /**
     * @description Esegue la navigazione verso la pagina di errore CreditCheck
     * @returns void
     */
    public goToCreditCheckError(errorCode = 'GENERIC'): Observable<void> {
        return from(
            this.router.navigate([RoutesPaths.KoCreditCheck], {
                queryParams: {
                    errorCode,
                },
            })
        ).pipe(
            tap((navigated) => {
                if (!navigated) {
                    throw new Error();
                }
            }),
            map(() => null)
        );
    }

    public skipTaxCodeChecks(flowType: FlowType, productTypes: SelectProductTypes): boolean {
        return (
            flowTypeUtil(flowType).inMacroFlowTypes(
                [MacroFlowType.SwitchIn, MacroFlowType.Administrative, MacroFlowType.Vip],
                [MacroFlowType.VolturaSwitchIn, MacroFlowType.Administrative, MacroFlowType.Vip],
                [MacroFlowType.Attivazione, MacroFlowType.Administrative, MacroFlowType.Vip],
                [MacroFlowType.Voltura, MacroFlowType.Administrative, MacroFlowType.Vip]
            ) || productTypes?.containsOnlyInsurance
        );
    }

    /**
     * Controlla se i dati del credit check nello state sono relativi al CF o P.IVA specificato in ingresso e sono ancora validi dal punto di vista temporale
     * @param currentSearchCode Codice fiscale o partiva iva
     * @returns
     */
    public isCreditCheckStillValid(currentSearchCode: string): Observable<boolean> {
        return this.store.select(selectCreditCheckStatus).pipe(
            take(1),
            map(({ checkDate, code }) => {
                const creditCheckDate = moment(checkDate);
                const yesterday = moment().add(-1, 'day');
                return code === currentSearchCode && creditCheckDate.isAfter(yesterday, 'day');
            })
        );
    }

    /**
     * Controlla se deve essere effettuato o meno, il controllo credito in base a FlowType e prodotti nel carrello
     * @returns
     */
    public skipCreditCheck(): Observable<boolean> {
        return this.store.select(selectFlowType).pipe(
            take(1),
            map((flowType) => {
                return flowTypeUtil(flowType).inMacroFlowTypes(
                    [MacroFlowType.SwitchIn, MacroFlowType.Administrative, MacroFlowType.Vip],
                    [MacroFlowType.VolturaSwitchIn, MacroFlowType.Administrative, MacroFlowType.Vip],
                    [
                        MacroFlowType.Attivazione,
                        MacroFlowType.Administrative,
                        MacroFlowType.Vip,
                        MacroFlowType.Recovery,
                    ],
                    [MacroFlowType.Voltura, MacroFlowType.Administrative, MacroFlowType.Vip],
                    MacroFlowType.CambioProdotto
                );
            }),
            mergeMap((skipCC) => {
                // Su CPQ è stata creata una tabella con le informazioni che indicano se deve essere effettuato il credit check
                // a seconda del contenuto del cart. Attualmente sono configurati gli extracommodity
                return this.store.select(v2SelectVisibleProducts()).pipe(
                    take(1),
                    mergeMap((products) => {
                        if (!skipCC && hasOnlyExtraCommodities(products)) {
                            return this.creditCheckRuleSrv
                                .shouldExecuteCreditCheck(products)
                                .pipe(map((shouldExecuteCC) => !shouldExecuteCC));
                        }
                        return of(skipCC);
                    }),
                    tap((skip) => console.log('Should skip credit check', skip))
                );
            })
        );
    }

    public checkBalance(
        skipCC: boolean,
        customerCode: string
    ): Observable<{ esito: boolean; dettaglio: CheckBalanceResult }> {
        // La chiamata deve essere effettuata solo in caso di presenza prodotti NDS Rateizzo
        // Se nessun prodotto rateizzo OPPURE feature flag disattivo => esito: NON NECESSARIO
        // Se prodotto rateizzo => chiama servizio esterno
        return this.store.select(v2SelectExtracommoditiesByFilter()).pipe(
            take(1),
            filter(
                (products) =>
                    this.featureToggleSrv.isCheckBalanceEnabled &&
                    !skipCC &&
                    products.some((p) => p.paymentInfo.paymentType === AptPaymentType.Rateizzo)
            ),
            map((products) =>
                products.reduce((sum, curr) => (sum += +curr.prices.netPrice * curr.configurations.quantity), 0)
            ),
            mergeMap((totalAmount) =>
                this.ccPrv.checkBalance(totalAmount, customerCode).pipe(
                    tap((res) => {
                        if (
                            res?.status !== StatusResponse.Success ||
                            res?.response?.status !== StatusDDLResponse.Success ||
                            !res?.response?.esito
                        ) {
                            throw new ServiceError(
                                'CHECK_BALANCE',
                                res?.errorManagement?.errorDescription ||
                                    res?.response?.errorManagement?.errorDescription ||
                                    'Check Balance - Servizio indisponibile',
                                'LOW',
                                { response: res }
                            );
                        }
                    }),
                    catchError((error) => {
                        this.logger.error(
                            'Check Balance',
                            'Errore durante il controllo soglia. Imposto valore di RETRY',
                            error
                        );
                        return of(<BaseApiResponse<CheckBalanceResponse>>{
                            response: {
                                esito: CheckBalanceResult.Retry,
                            },
                        }) as Observable<BaseApiResponse<CheckBalanceResponse>>;
                    })
                )
            ),
            mapOrEmpty(
                (res) => ({
                    esito: [CheckBalanceResult.Retry, CheckBalanceResult.OK].includes(res?.response?.esito),
                    dettaglio: res?.response?.esito,
                }),
                () => ({
                    esito: true,
                    dettaglio: CheckBalanceResult.NotNeeded,
                })
            )
        );
    }

    // TODO Lorenzo - Cerca di risolvere Circular dipendency injection
    private get ccPrv() {
        return this.injector.get(CreditCheckProvider);
    }
    private get cartSrv() {
        return this.injector.get(CartService);
    }
}
