import { Action, createReducer, on } from '@ngrx/store';
import * as boA from '../actions/bo.actions';
import { BoState } from '../models/bo-state';

export const initialState: BoState = new BoState();

const reducer = createReducer(
    initialState,
    on(boA.setMonitoraggioFilterBar, (state, { p }) => {
        return { ...state, monitoraggioFilter: p };
    }),
    on(boA.setGestioneFilterBar, (state, { p }) => {
        return { ...state, gestioneFilter: p };
    }),
    on(boA.setCarrelliFilterBar, (state, { p }) => {
        return { ...state, carrelliFilter: p };
    }),
    on(boA.setSelectedTab, (state, { p }) => {
        return { ...state, selectedTab: p };
    }),
    on(boA.setSelectedOpMode, (state, { p }) => {
        return { ...state, selectedOpMode: p };
    }),
    on(boA.setSelectedMacroOpMode, (state, { p }) => {
        return { ...state, selectedMacroOpMode: p };
    }),
    on(boA.setSelectedMostUsedActions, (state, { p }) => {
        return { ...state, selectedMostUsedAction: p };
    }),
    on(boA.setSelectInamissibilityMode, (state, { p }) => {
        return { ...state, selectedInamissibilityMode: p };
    }),
    on(boA.setCommodityType, (state, { p }) => {
        return { ...state, selectedCommodity: p };
    }),
    on(boA.setAgentFilter, (state, { p }) => {
        return {
            ...state,
            monitoraggioFilter: {
                ...state.monitoraggioFilter,
                agent: p,
            },
            gestioneFilter: {
                ...state.gestioneFilter,
                agent: p,
            },
        };
    }),
    on(boA.setAgencyBranchFilter, (state, { p }) => {
        return {
            ...state,
            monitoraggioFilter: {
                ...state.monitoraggioFilter,
                agencyBranch: p,
            },
            gestioneFilter: {
                ...state.gestioneFilter,
                agencyBranch: p,
            },
        };
    }),
    on(boA.resetSearchFilters, (state, { p }) => ({
        ...new BoState(p),
    })),
    on(boA.setMonitoraggioFiltersAndOpMode, (state, { f, opMode }) => {
        return { ...state, monitoraggioFilter: f, selectedOpMode: opMode };
    }),
    // on(boA.setMonitoraggioOrderFilters, (state, { f, opMode, inadmiss}) => {
    //     return { ...state, monitoraggioOrderFilter: f, selectedOpMode: opMode,selectedInamissibilityMode:inadmiss };
    // }),
    on(boA.setBOQuoteId, (state, { p }) => {
        return { ...state, selectedQuoteId: p };
    }),
    on(boA.setChannelFilter, (state, { p }) => {
        return {
            ...state,
            monitoraggioFilter: {
                ...state.monitoraggioFilter,
                channels: p,
            },
            gestioneFilter: {
                ...state.gestioneFilter,
                channels: p,
            },
        };
    }),
    on(boA.setVirtualAgencyFilter, (state, { p }) => {
        return {
            ...state,
            monitoraggioFilter: {
                ...state.monitoraggioFilter,
                virtualAgencies: p,
            },
            gestioneFilter: {
                ...state.gestioneFilter,
                virtualAgencies: p,
            },
        };
    }),
    on(boA.setMassiveActionsFilters, (state, { massiveActionsFilters }) => {
        return { ...state, massiveActionsFilters };
    }),
    on(boA.setBillInstalmentSelectedBill, (state, { billInstalmentSelectedBill }) => {
        return { ...state, billInstalmentSelectedBill };
    }),
    on(boA.setBillInstalmentClientCode, (state, { billInstalmentClientCode }) => {
        return { ...state, billInstalmentClientAccountCode: billInstalmentClientCode };
    }),
);

export function boReducer(state: BoState | undefined, action: Action): BoState {
    return reducer(state, action);
}
